import React, { useState } from "react";

interface DataSourceOptionProps {
  options: string[];
  selectedOption: string;
  onOptionChange: (option: string) => void;
}

const DataSourceOption: React.FC<DataSourceOptionProps> = ({ options, selectedOption, onOptionChange }) => {
  const [activeIndex, setActiveIndex] = useState(options.indexOf(selectedOption));

  const handleClick = (index: number) => {
    setActiveIndex(index);
    onOptionChange(options[index]);
  };

  return (
    <div className="flex transition-all duration-1000 rounded-lg bg-grey-75 py-[3px] px-[4px] w-[270px]">
      {options.map((option, index) => (
        <button
          key={index}
          className={`
            w-full
            py-2
            px-4
            flex justify-center items-center
            rounded-lg
            ${index === activeIndex ? " shadow-md bg-shade-white text-shade-black" : "inherit  text-grey-500"}
            
          `}
          onClick={() => handleClick(index)}
        >
          {option}
        </button>
      ))}
    </div>
  );
};

export default DataSourceOption;
