import Typography from "../shared/Typography";
import { IoMdClose } from "react-icons/io";
import { Button } from "../shared/Button";
import classNames from "classnames";

interface IRegenerateBlockModal {
  onClose: () => void;
  setComment: (value: string) => void;
  handleRefresh: () => void;
  isFetchingBlock?: boolean;
}

export function RegenerateBlockModal(props: IRegenerateBlockModal) {
  const { onClose, handleRefresh, setComment, isFetchingBlock } = props;

  return (
    <div className={classNames("flex bg-[#FFF] flex-col rounded-md w-[470px]")}>
      <div className="flex justify-between items-center px-6 pb-4 pt-6  border-b-[1px] border-b-grey-100">
        <Typography variant="textxl" className="font-semibold">
          Regenerate Report
        </Typography>
        <div onClick={() => onClose()} className="hover:cursor-pointer h-6 w-6">
          <IoMdClose className="w-full h-full" />
        </div>
      </div>
      <div className="">
        <div className="px-6 py-4">
          <div className="flex flex-col gap-1 mb-2">
            <Typography variant="textsm" className="text-grey-900 text-left !font-medium">
              Add Comment (optional)
            </Typography>
            <textarea
              className="h-24 mt-[2px] px-3 py-2 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-primary-200 focus:ring-primary-200 block w-full rounded-md sm:text-sm focus:ring-1"
              placeholder="Comment"
              onChange={(event) => {
                setComment(event.target.value);
              }}
            />
          </div>
        </div>
        <div className="flex justify-between items-center px-6 pb-6 pt-4  border-t-[1px] border-t-grey-100">
          <Button type="button" title="Cancel" color="info" onClick={() => onClose()} />
          <Button disabled={isFetchingBlock} type="button" title="Regenerate" onClick={handleRefresh} />
        </div>{" "}
      </div>
    </div>
  );
}
