export interface IDataSource {
  created: string;
  id: string;
  metadata?: string | null;
  name: string | null;
  report_id?: string | null;
  type?: string | null;
  type_class?: string | null;
  updated: string;
  origin?: string;
  status: UploadStatusEnum | string;
}


export enum UploadStatusEnum {
  COMPLETE = "COMPLETE",
  FAILED = "FAILED",
  PROCESSING = "PROCESSING",
}

export interface IDataSourcesAPIResponse {
  data: {
    data_sources: Partial<IDataSource>[];
    total: number;
  };
}

export interface IDataSourcesFoldersAPIResponse {
  data: {
    folders: Partial<IDataSource>[];
    total: number;
  };
}

export interface IDataSourcesArray {
  data_sources: Partial<IDataSource>[];
}

export enum FileTypes {
  CSV = "csv",
  XLSX = "xlsx",
  PDF = "pdf",
  DOCX = "docx",
}

export enum UploadDataType {
  FOLDER = "folder",
  FILE = "file",
}

export enum DataSources {
  LOCAL = "local",
  AMAZON_S3 = "amazon_s3",
  GOOGLE_DRIVE = "google_drive",
  DROPBOX = "dropbox",
}

export enum ModelInView {
  DATATYPE = "datatype",
  UPLOAD = "upload",
  SOURCE = "source",
}

export enum S3ModelInView {
  ACCESS = "access",
  FOLDER = "folder",
}

export enum ISeparatorEnum {
  COMMA = "comma",
  SEMICOLON = "semicolon",
  TAB = "tab",
  PIPE = "pipe",
}

export enum ISeparatorEnumValue {
  COMMA = ",",
  SEMICOLON = ";",
  TAB = "\t",
  PIPE = "|",
}

export interface IAmazonS3Payload {
  access_key: string;
  access_secret: string;
  bucket_name: string;
  file_location?: string;
  folder?: string;
  name?: string;
  type?: string;
  separator?: string;
}

export interface IAddFilesToFolder {
  data_sources: string[];
  id: string;
}
