interface ILoading {
  numberOfLoaders?: number;
  loaderType?: string;
  screenSize?: string;
  height?: number;
  className?: string;
}

export default function Loading(props: ILoading) {
  const { numberOfLoaders = 3, height = 5, className } = props;

  const baseClass = `${className} h-${height} rounded`;

  return (
      <div className="h-full animate-pulse flex flex-col gap-6 w-full">
        {[...Array(numberOfLoaders)].map((_, index) => (
          <div key={index} className={baseClass} />
        ))}
      </div>
  );
}
