import { useState } from "react";
import Typography from "../../shared/Typography";
import { IoMdClose } from "react-icons/io";
import { Button } from "../../shared/Button";
import { DropZone, IDropzoneType } from "../../shared/DropZone";
import classNames from "classnames";
import { DataSources, FileTypes, ISeparatorEnumValue, ModelInView, UploadDataType } from "types";
import { useUploadDataSource } from "services";
import { toast } from "react-toastify";
import { queryClient } from "index";
import { amazon_s3, csv_icon, docx_icon, dropbox_icon, google_drive_icon, pdf_icon, upload_icon, upload_local_icon, xlsx_icon } from "assets/svg";
import { dataSourceName, getIcon } from "utils";
import { AmazonS3 } from "..";

interface IAddDataSource {
  onClose: () => void;
  report_id?: string;
  folder_id?: string;
  dataType?: string;
}

const fileTypes: { name: string; icon: React.ReactNode; slug: string }[] = [
  {
    name: "pdf",
    slug: "Pdf",
    icon: <img src={pdf_icon} alt="pdf" className="h-10 w-10" />,
  },
  {
    name: "csv",
    slug: "Csv",
    icon: <img src={csv_icon} alt="csv" className="h-10 w-10" />,
  },
  {
    name: "xlsx",
    slug: "Xlsx",
    icon: <img src={xlsx_icon} alt="xlsx" className="h-10 w-10" />,
  },
  {
    name: "docx",
    slug: "Docx",
    icon: <img src={docx_icon} alt="docx" className="h-10 w-10" />,
  },
];

const dataSourceTypes: { name: string; icon: React.ReactNode; slug: string }[] = [
  {
    name: "local",
    slug: "Upload from computer",
    icon: <img src={upload_local_icon} alt="local" className="h-8 w-10" />,
  },
  {
    name: "amazon_s3",
    slug: "Amazon S3",
    icon: <img src={amazon_s3} alt="amaozn s3" className="h-10 w-10" />,
  },
  {
    name: "google_drive",
    slug: "Google Drive",
    icon: <img src={google_drive_icon} alt="google drive" className="h-10 w-10" />,
  },
  {
    name: "dropbox",
    slug: "Dropbox",
    icon: <img src={dropbox_icon} alt="dropbox" className="h-10 w-10" />,
  },
];

export function AddDataSourceModal(props: IAddDataSource) {
  const { onClose, report_id, dataType, folder_id } = props;

   const [folderName, setFolderName] = useState("");

  const [modalInView, setModalInView] = useState<ModelInView>(ModelInView.UPLOAD);
  const [selectedFileType, setSelectedFileType] = useState<FileTypes>(FileTypes.PDF);
  const [selectedDataSource, setSelectedDataSource] = useState<DataSources>(DataSources.LOCAL);
  const [fileName, setFileName] = useState<string>();
  const [separator, setSeparator] = useState<ISeparatorEnumValue>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [uploadDataType, setUploadDataType] = useState<UploadDataType>(UploadDataType.FILE);

  const [heading, setHeading] = useState("");

  const [file, setFile] = useState<File>();

  const { uploadDatasource } = useUploadDataSource();

  const formData = new FormData();


  const handleUpload = async () => {
    setIsSubmitting(true);

    formData.append("file", file as File);
    formData.append("data_source_name", fileName as string);
    if (report_id) {
      formData.append("report_id", report_id as string);
    }

    const customId = "custom-toast-id";

    uploadDatasource(formData, {
      onError: (error, variables, context) => {
        toast.error(error?.message || "Error creating Datasource", {
          position: "top-center",
          toastId: customId,
          autoClose: 2000,
          theme: "colored",
        });
        setIsSubmitting(false);
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({ queryKey: ["datasources"] });

        if (report_id) {
          queryClient.invalidateQueries({ queryKey: ["report-datasources", report_id] });
        }
        onClose();

        toast.success("Datasource Created Successfully", {
          position: "top-center",
          toastId: customId,
          autoClose: 1000,
          theme: "colored",
        });
      },
    });
  };

  const disableButton = !fileName || !file || isSubmitting;

  const icon = getIcon(selectedFileType);

  const dataSourceIcon = getIcon(selectedDataSource);

  return (
    <div className={classNames("flex bg-[#FFF] flex-col rounded-md w-[470px]")}>
      {/* {modalInView === ModelInView.DATATYPE && (
        <div className="">
          <div className="flex justify-between items-center px-6 pb-4 pt-6  border-b-[1px] border-b-grey-100">
            {modalInView === ModelInView.DATATYPE && (
              <Typography variant="textxl" className="font-semibold">
                Choose File Type
              </Typography>
            )}

            <div onClick={() => onClose()} className="hover:cursor-pointer h-6 w-6">
              <IoMdClose className="w-full h-full" />
            </div>
          </div>
          <div className="flex flex-col gap-2 p-6 ">
            {" "}
            {fileTypes.map((type, index) => (
              <div
                className="flex items-center hover:cursor-pointer hover:bg-grey-75 p-2 rounded-md"
                key={index}
                onClick={(e) => setSelectedFileType(type.name as FileTypes)}
              >
                <input
                  type="radio"
                  id="radioColor"
                  name="fileType"
                  value={type.name}
                  checked={selectedFileType === type.name}
                  onChange={(e) => setSelectedFileType(e.target.value as FileTypes)}
                  className="mr-4 w-6 h-6 hover:cursor-pointer"
                />
                <div className="mr-3">{type.icon}</div>
                <Typography variant="textsm" className="font-medium">
                  {type.slug} File
                </Typography>
              </div>
            ))}
          </div>
          <div className="flex justify-between items-center px-6 pb-6 pt-4  border-t-[1px] border-t-grey-100">
            <Button type="button" title="Cancel" color="info" onClick={() => onClose()} />
            <Button title="Continue" onClick={() => setModalInView(ModelInView.SOURCE)} />
          </div>{" "}
        </div>
      )} */}
      {modalInView === ModelInView.SOURCE && (
        <div className="">
          <div className="flex justify-between items-center px-6 pb-4 pt-6  border-b-[1px] border-b-grey-100">
            <Typography variant="textxl" className="font-semibold">
              Choose Source
            </Typography>

            <div onClick={() => onClose()} className="hover:cursor-pointer h-6 w-6">
              <IoMdClose className="w-full h-full" />
            </div>
          </div>
          <div className="flex flex-col gap-2 p-6">
            {" "}
            {dataSourceTypes.map((type, index) => (
              <div
                key={index}
                className={classNames("flex items-center hover:cursor-pointer hover:bg-grey-75 p-2 rounded-md", {
                  "opacity-50 pointer-events-none": type.name === DataSources.DROPBOX || type.name === DataSources.GOOGLE_DRIVE,
                })}
                onClick={(e) => setSelectedDataSource(type.name as DataSources)}
              >
                <input
                  type="radio"
                  id="radioColor"
                  name="fileType"
                  disabled={type.name === DataSources.DROPBOX || type.name === DataSources.GOOGLE_DRIVE}
                  value={type.name}
                  checked={selectedDataSource === type.name}
                  onChange={(e) => setSelectedDataSource(e.target.value as DataSources)}
                  className="mr-4 w-6 h-6 hover:cursor-pointer"
                />
                <div className="mr-3 w-8">{type.icon}</div>
                <Typography variant="textsm" className={classNames("font-medium")}>
                  {type.slug}
                </Typography>
              </div>
            ))}
          </div>
          <div className="flex justify-between items-center px-6 pb-6 pt-4 border-t-[1px] border-t-grey-100">
            {" "}
            <Button type="button" title="Go back" color="info" onClick={() => setModalInView(ModelInView.DATATYPE)} />
            <Button title="Continue" onClick={() => setModalInView(ModelInView.UPLOAD)} />
          </div>{" "}
        </div>
      )}

      {modalInView === ModelInView.UPLOAD && (
        <>
          {selectedDataSource === DataSources.LOCAL && (
            <div className="">
              <div className="flex justify-between items-center px-6 pb-4 pt-6  border-b-[1px] border-b-grey-100">
                <div className="flex items-center gap-2">
                  <img src={icon} alt="file type" className="h-8 w-8" />
                  <Typography variant="textxl" className="font-semibold">
                    Choose Source
                  </Typography>
                </div>

                <div onClick={() => onClose()} className="hover:cursor-pointer h-6 w-6">
                  <IoMdClose className="w-full h-full" />
                </div>
              </div>
              {/* <div className="flex flex-col gap-1 px-6 mt-3">
                <Typography variant="textsm" className="text-grey-900 text-left !font-medium">
                  Name
                </Typography>
                <input
                  className="h-12 mt-[2px] px-3 py-2 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-primary-200 focus:ring-primary-200 block w-full rounded-md sm:text-sm focus:ring-1"
                  placeholder="Title"
                  onChange={(event) => {
                    setFileName(event.target.value);
                  }}
                />
              </div> */}
              <div className="flex flex-col gap-1 px-6 mt-5 mb-6">
                <div className="flex justify-center flex-col items-center gap-4">
                  <Typography variant="displaysm" className="text-grey-900 text-left font-bold">
                    Upload Data Sources
                  </Typography>
                  <Typography variant="textsm" className="text-grey-900 text-left !font-medium">
                    Upload multiple files and folders at once
                  </Typography>
                </div>
                <div className="flex flex-col gap-1">
                  <DropZone dropZoneType={IDropzoneType.SMALL} setFiles={setFile} fileType={selectedFileType} setFolderName={setFolderName} />{" "}
                </div>
              </div>
              {selectedFileType === FileTypes.CSV && (
                <>
                  <div className="flex flex-col gap-1 px-6 mt-5 mb-8">
                    <Typography variant="body2" className="!font-semibold text-shade-black text-left">
                      Select a Separator
                    </Typography>

                    <div className="flex gap-3 ">
                      <div
                        className={classNames(
                          "w-[98px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 flex items-center justify-center hover:cursor-pointer",
                          {
                            "bg-primary-50": separator === ISeparatorEnumValue.COMMA,
                          }
                        )}
                        onClick={() => {
                          setSeparator(ISeparatorEnumValue.COMMA);
                        }}
                      >
                        <div className="flex justify-center gap-2 items-center w-full p-2">
                          <Typography variant="textsm" className="text-grey-500">
                            Comma
                          </Typography>
                          <Typography variant="textsm" className="text-grey-500">
                            (,)
                          </Typography>
                        </div>
                      </div>
                      <div
                        className={classNames(
                          "w-[98px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200  flex items-center justify-center hover:cursor-pointer",
                          {
                            "bg-primary-50": separator === ISeparatorEnumValue.TAB,
                          }
                        )}
                        onClick={() => {
                          setSeparator(ISeparatorEnumValue.TAB);
                        }}
                      >
                        <div className="flex justify-center gap-2 items-center w-full p-2">
                          <Typography variant="textsm" className="text-grey-500">
                            Tab
                          </Typography>
                          <Typography variant="textsm" className="text-grey-500">
                            (\t)
                          </Typography>
                        </div>
                      </div>
                      <div
                        className={classNames(
                          "w-[98px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200  flex items-center justify-center hover:cursor-pointer",
                          {
                            "bg-primary-50": separator === ISeparatorEnumValue.SEMICOLON,
                          }
                        )}
                        onClick={() => {
                          setSeparator(ISeparatorEnumValue.SEMICOLON);
                        }}
                      >
                        <div className="flex justify-center gap-2 items-center w-full p-2">
                          <Typography variant="textsm" className="text-grey-500">
                            Semicolon
                          </Typography>
                          <Typography variant="textsm" className="text-grey-500">
                            (;)
                          </Typography>
                        </div>
                      </div>

                      <div
                        className={classNames(
                          "w-[98px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200  flex items-center justify-center hover:cursor-pointer",
                          {
                            "bg-primary-50": separator === ISeparatorEnumValue.PIPE,
                          }
                        )}
                        onClick={() => {
                          setSeparator(ISeparatorEnumValue.PIPE);
                        }}
                      >
                        <div className="flex justify-center gap-2 items-center w-full p-2">
                          <Typography variant="textsm" className="text-grey-500">
                            Pipe
                          </Typography>
                          <Typography variant="textsm" className="text-grey-500">
                            (|)
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="flex justify-between items-center px-6 pb-6 pt-4  border-t-[1px] border-t-grey-100">
                <Button type="button" title="Go back" color="info" onClick={() => setModalInView(ModelInView.SOURCE)} />
                <Button disabled={disableButton} isSubmitting={isSubmitting} type="button" title="Save" onClick={handleUpload} />
              </div>{" "}
            </div>
          )}

          {selectedDataSource === DataSources.AMAZON_S3 && (
            <AmazonS3 setModalInView={setModalInView} selectedFileType={selectedFileType} onClose={onClose} report_id={report_id} />
          )}
        </>
      )}
    </div>
  );
}
