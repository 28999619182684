import { useState } from "react";
import classNames from "classnames";
import { DataSources, FileTypes, ModelInView } from "types";
import { useUploadMultipleFiles } from "services";
import { toast } from "react-toastify";
import { queryClient } from "index";
import { amazon_s3, dropbox_icon, google_drive_icon, upload_local_icon } from "assets/svg";
import Typography from "components/shared/Typography";
import { AmazonS3, Button, DropZone, IDropzoneType } from "components";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

interface IAddDataSource {
  onClose: () => void;
  report_id?: string;
  folder_id?: string;
  dataType?: string;
}

const dataSourceTypes: { name: string; icon: React.ReactNode; slug: string }[] = [
  {
    name: "local",
    slug: "Upload from computer",
    icon: <img src={upload_local_icon} alt="local" className="h-8 w-10" />,
  },
  {
    name: "amazon_s3",
    slug: "Amazon S3",
    icon: <img src={amazon_s3} alt="amaozn s3" className="h-10 w-10" />,
  },
  {
    name: "google_drive",
    slug: "Google Drive",
    icon: <img src={google_drive_icon} alt="google drive" className="h-10 w-10" />,
  },
  {
    name: "dropbox",
    slug: "Dropbox",
    icon: <img src={dropbox_icon} alt="dropbox" className="h-10 w-10" />,
  },
];

export function Upload() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const report_id = searchParams.get("report_id");
  const redirect = searchParams.get("redirect");
  const folder_id = searchParams.get("folder_id");
  const name = searchParams.get("name");
  const [modalInView, setModalInView] = useState<ModelInView>(ModelInView.SOURCE);
  const [selectedFileType, setSelectedFileType] = useState<FileTypes>(FileTypes.PDF);
  const [selectedDataSource, setSelectedDataSource] = useState<DataSources>(DataSources.LOCAL);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [files, setFiles] = useState<File[]>([]);
  const [folderName, setFolderName] = useState("");

  const { uploadMultipleFiles } = useUploadMultipleFiles();

  const [displayUploadedFiles, setdisplayUploadedFiles] = useState<boolean>(false);

  const handleUpload = async () => {
    setIsSubmitting(true);

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("file", file);
    });

    const customId = "custom-toast-id";

    if (folderName) {
      formData.append("new_folder_name", folderName as string);
      if (report_id) {
        formData.append("report_id", report_id as string);
      }
      if (folder_id) {
        formData.append("parent_id", folder_id as string);
      }

      uploadMultipleFiles(formData, {
        onError: (error, variables, context) => {
          toast.error(error?.message || "Error uploading files", {
            position: "top-center",
            toastId: customId,
            autoClose: 2000,
            theme: "colored",
          });
          setIsSubmitting(false);
        },
        onSuccess: (data, variables, context) => {
          queryClient.invalidateQueries({ queryKey: ["datasources"] });
          setdisplayUploadedFiles(true);
          setFiles([]);
          setTimeout(() => {
            if (redirect) {
              navigate(`/reports?redirect=data-sources`);
            } else if (folder_id) {
              navigate(`/data-sources/folder/${folder_id}?name=${name}`);
            } else {
              navigate(`/data-sources`);
            }
          }, 1000);
          toast.success("Background task initialted successfully", {
            position: "top-center",
            toastId: customId,
            autoClose: 1000,
            theme: "colored",
          });
        },
      });
    } else {
      if (report_id) {
        formData.append("report_id", report_id as string);
      }
      if (folder_id) {
        formData.append("parent_id", folder_id as string);
      }
      uploadMultipleFiles(formData, {
        onError: (error, variables, context) => {
          toast.error(error?.message || "Error uploading files", {
            position: "top-center",
            toastId: customId,
            autoClose: 2000,
            theme: "colored",
          });
          setIsSubmitting(false);
        },
        onSuccess: (data, variables, context) => {
          queryClient.invalidateQueries({ queryKey: ["datasources"] });

          if (report_id) {
            queryClient.invalidateQueries({ queryKey: ["report-datasources", report_id] });
            window.history.back();
          }

          setdisplayUploadedFiles(true);
          setFiles([]);
          setTimeout(() => {
            if (redirect) {
              navigate(`/reports?redirect=data-sources`);
            } else if (folder_id) {
              navigate(`/data-sources/folder/${folder_id}?name=${name}`);
            } else {
              navigate(`/data-sources`);
            }
          }, 1000);
          toast.success("Background task initialted successfully", {
            position: "top-center",
            toastId: customId,
            autoClose: 1000,
            theme: "colored",
          });
        },
      });
    }
  };

  const disableButton = files.length === 0 || isSubmitting;

  return (
    <div className={classNames("flex bg-[#FFF] flex-col rounded-lg w-[526px]")}>
      {modalInView === ModelInView.SOURCE && (
        <div className="">
          <div className="flex justify-center items-center px-6 mb-10 pt-6">
            <Typography variant="textxl" className="font-semibold">
              Choose Source
            </Typography>
          </div>
          <div className="flex flex-col gap-2 p-6">
            {" "}
            {dataSourceTypes.map((type, index) => (
              <div
                key={index}
                className={classNames("flex items-center hover:cursor-pointer hover:bg-grey-75 p-2 rounded-md justify-between", {
                  "opacity-50 pointer-events-none": type.name === DataSources.DROPBOX || type.name === DataSources.GOOGLE_DRIVE,
                })}
                onClick={(e) => setSelectedDataSource(type.name as DataSources)}
              >
                <div className="flex items-center gap-1">
                  <div className="mr-3 w-6">{type.icon}</div>
                  <Typography variant="textsm" className={classNames("font-medium")}>
                    {type.slug}
                  </Typography>
                </div>
                <input
                  type="radio"
                  id="radioColor"
                  name="fileType"
                  disabled={type.name === DataSources.DROPBOX || type.name === DataSources.GOOGLE_DRIVE}
                  value={type.name}
                  checked={selectedDataSource === type.name}
                  onChange={(e) => setSelectedDataSource(e.target.value as DataSources)}
                  className="mr-4 w-6 h-6 hover:cursor-pointer"
                />
              </div>
            ))}
          </div>
          <div className="flex justify-between items-center px-6 pb-6 pt-4">
            {" "}
            <Button type="button" title="Go back" color="info" onClick={() => window.history.back()} />
            <Button title="Continue" onClick={() => setModalInView(ModelInView.UPLOAD)} />
          </div>{" "}
        </div>
      )}

      {modalInView === ModelInView.UPLOAD && (
        <>
          {selectedDataSource === DataSources.LOCAL && (
            <div className="">
              <div className="flex flex-col gap-1 mt-5 mb-6">
                <div className="flex justify-center flex-col items-center gap-4 mb-16">
                  <Typography variant="displaysm" className="text-grey-900 text-left font-bold">
                    Upload Data Sources
                  </Typography>
                  <Typography variant="textsm" className="text-grey-900 text-left !font-medium">
                    Upload multiple files and folders at once
                  </Typography>
                </div>
                <div className="flex flex-col gap-1">
                  <DropZone dropZoneType={IDropzoneType.SMALL} setFiles={setFiles} files={files} setFolderName={setFolderName} />{" "}
                </div>
              </div>
              <div className="flex justify-between items-center pb-6 pt-4 mt-10">
                <Button type="button" title="Cancel" color="info" onClick={() => setModalInView(ModelInView.SOURCE)} />
                <Button disabled={disableButton} isSubmitting={isSubmitting} type="button" title="Upload" onClick={handleUpload} />
              </div>{" "}
            </div>
          )}

          {selectedDataSource === DataSources.AMAZON_S3 && (
            <AmazonS3
              setModalInView={setModalInView}
              selectedFileType={selectedFileType}
              report_id={report_id as string}
              folder_id={folder_id as string}
              folder_name={name as string}
            />
          )}
        </>
      )}
    </div>
  );
}
