import classNames from "classnames";
import { AddFilesToFolderModal } from "components";
import DataSourceComponent from "components/Datasources/DataSourceComponent";
import DataSourceOption from "components/Datasources/DataSourceOption";
import EmptyFolder from "components/Datasources/Folders/EmptyFolder";
import DocxRenderer from "components/blocks/DocxRenderer";
import PdfRenderer from "components/blocks/PdfRenderer";
import { Button, Modal, Pagination } from "components/shared";
import Loading from "components/shared/Loading";
import Typography from "components/shared/Typography";
import { AppContext } from "providers/AppContext";
import { useContext, useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { MdAdd } from "react-icons/md";
import { useNavigate, useNavigation, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useFetchDataSources, useGetFolderDetails } from "services";
import { IDataSource } from "types";

export default function Folder() {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const params = useParams();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const { id } = params;

  const name = searchParams.get("name");

  const [currentFolderPage, setCurrentFolderPage] = useState<number>(1);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const { data, isFetching, error } = useGetFolderDetails(currentFolderPage, id as string);

  const query = {
    page: String(currentPage),
    triggerFetch : false
  };

  const { data: AllDataSource, isFetching: isFetchingAll, error: isErrorAll } = useFetchDataSources(query);

  const goBack = () => {
    window.history.back();
  };

  const allData = AllDataSource?.data?.data_sources as Partial<IDataSource>[];

  const dataSources = data?.data?.data_sources as Partial<IDataSource>[];

  const displayEmpty = dataSources?.length === 0 && !isFetching && !error;

  const total = data?.data.total as number;

  const displayDataSources = dataSources && dataSources.length > 0 && !isFetching;

  const { isPdfOpen, dataSourceDownload, dataSourceToDownloadType, setDataSource, closePdf, togglePdfModal } = useContext(AppContext);

  useEffect(() => {
    if (isPdfOpen) {
      togglePdfModal();
      setDataSource("");
    }
  }, []);

  return (
    <div className={classNames("flex w-full justify-center transition-all duration-300")}>
      <div
        className={classNames("flex lg:max-w-[900px] md:max-w-[800px] pb-[109px] transition-all duration-300 flex-col", {
          "justify-center w-full border-none items-center": !isPdfOpen,
          "w-[50%] px-[20px] pt-[106px] border-r-[1px] border-grey-100": isPdfOpen,
        })}
      >
        {modalOpen && (
          <Modal
            onClose={() => setModalOpen(false)}
            open={modalOpen}
            variant="full"
            children={
              <AddFilesToFolderModal
                files={allData?.filter((file: Partial<IDataSource>) => file.type !== "folder")}
                onClose={() => setModalOpen(false)}
                folder_id={id}
              />
            }
          />
        )}
        <div>
          <div className="flex justify-start w-[700px]">
            <div className="flex gap-3 items-center hover:cursor-pointer" onClick={() => goBack()}>
              <FaArrowLeftLong />{" "}
              <Typography variant="body2" className="text-grey-900">
                Go Back
              </Typography>
            </div>
          </div>
          <div className="flex justify-center">
            <Typography variant="displaysm" className="font-bold text-grey-900">
              {name}
            </Typography>
          </div>
          {error && (
            <div className="mt-40 w-full flex justify-center items-center">
              <Typography variant="displaysm" weight="semibold" className="text-shade-black text-center">
                There was an unexpected error while retrieving data sources
              </Typography>
            </div>
          )}
          {isFetching && (
            <div className={classNames("h-full mt-4 w-[700px] mx-auto")}>
              <div className="flex justify-end items-center  mb-8">
                <div className="flex items-center gap-4">
                  <div className="w-40">
                    <Loading numberOfLoaders={1} className="bg-grey-200 !h-12" />
                  </div>
                  <div className="w-48">
                    <Loading numberOfLoaders={1} className="bg-grey-200 !h-12" />
                  </div>
                </div>
              </div>
              <Loading numberOfLoaders={5} className="bg-grey-200 !h-16" />
            </div>
          )}
          {displayEmpty && (
            <div className="flex h-full w-full items-center justify-center mt-1">
              <EmptyFolder empty={total === 0 ? true : false} setOpen={setModalOpen} folderId={id} folderName={name as string} />
            </div>
          )}
          {displayDataSources && (
            <>
              <div className="flex justify-end items-center mb-8 mt-4">
                <div className="flex gap-4 items-center">
                  <Button icon={<MdAdd />} title={"Select Files"} onClick={() => setModalOpen(true)} />
                  <Button
                    icon={<MdAdd />}
                    title={"Upload Data Sources"}
                    onClick={() => navigate(`/data-sources/upload?folder_id=${id}&name=${name}`)}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-8">
                <div className="flex flex-col gap-5">
                  {dataSources?.map((dataSource) => (
                    <div key={dataSource.id}>
                      <DataSourceComponent
                        name={dataSource.name as string}
                        id={dataSource.id as string}
                        updated={dataSource.updated as string}
                        type={dataSource.type as string}
                        origin={dataSource.origin as string}
                        status={dataSource.status as string}
                      />
                    </div>
                  ))}
                  {total && total > 10 && (
                    <div className="mt-2">
                      <Pagination onChangePage={setCurrentFolderPage} pageSize={10} currentPage={currentFolderPage} total={total || 0} />
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div
        className={classNames(" transition-all duration-300 ease-out", {
          "w-[50%] block  pt-[106px] px-10 max-w-[800px]": isPdfOpen,
          "hidden w-[0px] transition-all duration-300 ease-out": !isPdfOpen,
        })}
      >
        <div className="sticky top-0 overflow-y-auto">
          <div className="h-full w-full pb-6">
            {dataSourceDownload && dataSourceToDownloadType === "pdf" && <PdfRenderer />}
            {dataSourceDownload && dataSourceToDownloadType === "docx" && <DocxRenderer />}
          </div>
        </div>
      </div>
    </div>
  );
}
