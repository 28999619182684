import classNames from "classnames";
import { Button } from "components/shared";
import Typography from "components/shared/Typography";
import { queryClient } from "index";
import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useCreateFolder } from "services";
import { customId } from "utils";

interface CreateFolderModal {
  onClose: () => void;
}

export default function CreateFolderModal(props: CreateFolderModal) {
  const { onClose } = props;

  const { createFolder } = useCreateFolder();

  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const disableButton = isSubmitting || !name;

  const handleCreateFolder = () => {
    setIsSubmitting(true);
    createFolder(
      { name, description },
      {
        onError: (error, variables, context) => {
          toast.error(error?.message || "Error creating Folder", {
            position: "top-center",
            toastId: customId,
            autoClose: 1500,
            theme: "colored",
          });
          setIsSubmitting(false);
        },
        onSuccess: (data, variables, context) => {
          queryClient.invalidateQueries({ queryKey: ["datasources-folders"] });

          const folderId = data?.data?.data_source_id;

          if (folderId) {
            navigate(`/data-sources/folder/${folderId}`);
          }

          onClose();
          setIsSubmitting(false);

          setName("");
          setDescription("");
          toast.success("Folder Created Successfully", {
            position: "top-center",
            toastId: customId,
            autoClose: 1000,
            theme: "colored",
          });
        },
      }
    );
  };
  return (
    <div className={classNames("flex bg-[#FFF] flex-col rounded-md w-[470px]")}>
      <div className="flex justify-between items-center px-6 pb-4 pt-6  border-b-[1px] border-b-grey-100">
        <Typography variant="textxl" className="font-semibold">
          Create Folder
        </Typography>

        <div onClick={() => onClose()} className="hover:cursor-pointer h-6 w-6">
          <IoMdClose className="w-full h-full" />
        </div>
      </div>
      <div className="pb-6">
        <div className="flex flex-col gap-1 px-6 mt-3">
          <Typography variant="textsm" className="text-grey-900 text-left !font-medium">
            Folder Name
          </Typography>
          <input
            className=" mt-[2px] px-3 py-3 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-primary-200 focus:ring-primary-200 block w-full rounded-md sm:text-sm focus:ring-1"
            placeholder="Name"
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
        </div>
        <div className="flex flex-col gap-1 px-6 mt-3">
          <Typography variant="textsm" className="text-grey-900 text-left !font-medium">
            Description (optional)
          </Typography>
          <input
            className="mt-[2px] px-3 py-3 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-primary-200 focus:ring-primary-200 block w-full rounded-md sm:text-sm focus:ring-1"
            placeholder="Description"
            onChange={(event) => {
              setDescription(event.target.value);
            }}
          />
        </div>
      </div>
      <div className="flex justify-between items-center pt-4 border-t-[1px] border-t-grey-100 px-6 pb-6">
        <Button type="button" disabled={isSubmitting} title="Go back" color="info" onClick={onClose} />
        <Button disabled={disableButton} isSubmitting={isSubmitting} type="submit" title="Create Folder" onClick={() => handleCreateFolder()} />
      </div>
    </div>
  );
}
