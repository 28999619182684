import { AddDataSourceModal, Button } from "components";
import Header from "components/shared/Header";
import { MdAdd } from "react-icons/md";
import { useContext, useEffect, useState } from "react";
import { Modal } from "components/shared/Modal";
import DataSourceOption from "components/Datasources/DataSourceOption";
import CreateFolderModal from "components/Datasources/modals/CreateFolderModal";
import classNames from "classnames";
import { AppContext } from "providers/AppContext";
import PdfRenderer from "components/blocks/PdfRenderer";
import DocxRenderer from "components/blocks/DocxRenderer";
import { useNavigate } from "react-router";
import Folders from "components/Datasources/Folders/Folders";
import ListDataSources from "components/Datasources/ListDataSources";

export function DataSources() {
  const headerProps = {
    title: "Data Sources",
    buttonTitle: "New Source",
  };

  const { isPdfOpen, dataSourceDownload, dataSourceToDownloadType, setDataSource, closePdf, togglePdfModal } = useContext(AppContext);

  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [createFolderModalOpen, setCreateFolderModalOpen] = useState<boolean>(false);

  const [option, setOption] = useState("Data Sources");

  useEffect(() => {
    if (isPdfOpen) {
      togglePdfModal();
      setDataSource("");
    }
  }, [option]);

  const [dataSourcesTotal, setDataSourcesTotal] = useState<number>(0);

  const dataSourcesAvailable = dataSourcesTotal > 0;

  return (
    <div className={classNames("flex w-full justify-center transition-all duration-300")}>
      <div
        className={classNames("flex lg:max-w-[900px] md:max-w-[800px] pb-[109px] transition-all duration-300 flex-col", {
          "justify-center w-full border-none items-center": !isPdfOpen,
          "w-[50%] px-[20px] pt-[106px] border-r-[1px] border-grey-100": isPdfOpen,
        })}
      >
        {modalOpen && (
          <Modal
            onClose={() => setModalOpen(false)}
            open={modalOpen}
            variant="full"
            children={<AddDataSourceModal onClose={() => setModalOpen(false)} />}
          />
        )}
        {createFolderModalOpen && (
          <Modal
            onClose={() => setCreateFolderModalOpen(false)}
            open={modalOpen}
            variant="full"
            children={<CreateFolderModal onClose={() => setCreateFolderModalOpen(false)} />}
          />
        )}
        <div>
          <>
            {dataSourcesAvailable && (
              <>
                <Header title={option} />

                <div className="flex justify-between items-center mb-8">
                  <DataSourceOption options={["Data Sources", "Folders"]} selectedOption={option} onOptionChange={(option) => setOption(option)} />

                  <div>
                    {option === "Data Sources" && (
                      <Button icon={<MdAdd />} title={headerProps.buttonTitle} onClick={() => navigate("/data-sources/upload")} />
                    )}
                    {option === "Folders" && <Button icon={<MdAdd />} title={"Create Folder"} onClick={() => setCreateFolderModalOpen(true)} />}
                  </div>
                </div>
              </>
            )}

            <div>
              {option === "Data Sources" && <ListDataSources setDataSourcesTotal={setDataSourcesTotal} />}
              {option === "Folders" && <Folders />}
            </div>
          </>
        </div>
      </div>
      <div
        className={classNames(" transition-all duration-300 ease-out", {
          "w-[50%] block  pt-[106px] px-10 max-w-[800px]": isPdfOpen,
          "hidden w-[0px] transition-all duration-300 ease-out": !isPdfOpen,
        })}
      >
        <div className="sticky top-0 overflow-y-auto">
          <div className="h-full w-full pb-6">
            {dataSourceDownload && dataSourceToDownloadType === "pdf" && <PdfRenderer />}
            {dataSourceDownload && dataSourceToDownloadType === "docx" && <DocxRenderer />}
          </div>
        </div>
      </div>
    </div>
  );
}
