import { Context_logo_dark, auth_placeholder_image } from "assets/images";
import { creation_success_icon, email_icon, password_lock_icon, person_icon, view_password_icon } from "assets/svg";
import classNames from "classnames";
import { Button } from "components";
import Typography from "components/shared/Typography";
import { UserContext } from "providers/UserContext";
import { ChangeEvent, useContext, useState } from "react";
import { Navigate, useNavigate } from "react-router";
import { useLogin, useRegister } from "services";
import { styled } from "styled-components";
import { SetDetails, getDetails } from "utils";
import * as Yup from "yup";

enum EFormInView {
  LOGIN = "login",
  REGISTER = "register",
  COMPLETE = "complete",
}

const BackgroundImage = styled.img`
  object-fit: cover;
  border-radius: 0.5rem;
  width: auto;
  height: 100%;
`;

export default function Auth() {
  const [formInView, setformInView] = useState<EFormInView>(EFormInView.LOGIN);

  const { user } = useContext(UserContext);

  const getUserToken = getDetails("token");

  const verifyToken = user.token || getUserToken;

  const { setUserContext } = useContext(UserContext);
  const navigate = useNavigate();

  const changeView = (view: EFormInView) => {
    setformInView(view);
  };

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loginError, setLoginError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const schema = Yup.string().matches(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const handleEmailChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredEmail = event.target.value;
    setLoginError("");
    setEmail(enteredEmail.trim());

    try {
      await schema.validate(enteredEmail);
      setEmailError("");
    } catch (error) {
      const errorMessage = error instanceof Yup.ValidationError ? error.message : "Invalid email address";
      setEmailError(errorMessage);
    }
  };

  const { login } = useLogin();

  const handleSignIn = async () => {
    setIsSubmitting(true);
    const data = { email, password };
    login(data, {
      onError: (error, variables, context) => {
        setLoginError(error.message);
        setIsSubmitting(false);
      },
      onSuccess: (data, variables, context) => {
        const { token } = data.data;
        const id = data.data.account_id;

        if (token) {
          setUserContext({ ...data?.data, id });
          SetDetails("token", token);
          navigate("/reports");
        }
      },
    });
  };

  const { register } = useRegister();

  const handleRegister = () => {
    setIsSubmitting(true);
    const data = { name: fullName, email, password };
    register(data, {
      onError: (error, variables, context) => {
        setLoginError(error.message);
        setIsSubmitting(false);
      },
      onSuccess: (data, variables, context) => {
        setformInView(EFormInView.LOGIN);
        setIsSubmitting(false);
      },
    });
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (formInView === EFormInView.LOGIN) {
        handleSignIn();
      } else if (formInView === EFormInView.REGISTER) {
        handleRegister();
      }
    }
  };

  if (verifyToken) {
    return <Navigate to="/reports" />;
  }

  return (
    <div className="flex justify-between items-center h-screen w-screen">
      {" "}
      <div className="flex-1  h-full flex justify-center items-center">
        <div
          className={classNames("", {
            "w-[500px]": formInView !== EFormInView.COMPLETE,
            "w-[400px]": formInView === EFormInView.COMPLETE,
          })}
        >
          <div className="mb-[8px] flex justify-start pl-4 w-[212px] h-[34px]">
            <img src={Context_logo_dark} alt="context data" />
          </div>{" "}
          {formInView === EFormInView.LOGIN && (
            <>
              <div className="p-6 flex flex-col gap-8">
                <Typography variant="displayxs" className="text-shade-black text-left" weight="bold">
                  Sign in to your account
                </Typography>
                <form>
                  <div className="flex flex-col gap-6">
                    <div className="relative rounded-md">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <img className="text-grey-500 sm:text-sm" alt="search" src={email_icon} />
                      </div>

                      <input
                        className={classNames(
                          "px-3 pl-10 py-4 bg-white border shadow-sm  placeholder-grey-500 focus:outline-none  block w-full rounded-md sm:text-sm ",
                          {
                            "border-error-600 ": emailError,
                            "border-grey-300 focus:border-primary-200 focus:ring-primary-200 focus:ring-1": !emailError,
                          }
                        )}
                        placeholder="Enter your email"
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                      />
                    </div>
                    <div>
                      <div className="relative rounded-md">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <img className="text-grey-500 sm:text-sm" alt="search" src={password_lock_icon} />
                        </div>

                        <input
                          className="px-3 pl-10 py-4 bg-white border shadow-sm border-grey-300 placeholder-grey-500 focus:outline-none focus:border-primary-200 focus:ring-primary-200 block w-full rounded-md sm:text-sm focus:ring-1"
                          placeholder="Enter your password"
                          autoComplete="current-password"
                          type={showPassword ? "text" : "password"}
                          onChange={(e) => {
                            setPassword(e.target.value.trim());
                            setLoginError("");
                          }}
                          onKeyDown={handleKeyPress}
                        />
                        <button type="button" className="absolute right-0 top-0 mt-3 mr-4 p-1 text-gray-500" onClick={togglePasswordVisibility}>
                          <img src={view_password_icon} className="text-grey-500 sm:text-sm" alt="view password" />
                        </button>
                      </div>

                      <div
                        className={classNames("flex justify-start", {
                          "mt-2": loginError,
                          "mt-[30px]": !loginError,
                        })}
                      >
                        {loginError && (
                          <Typography variant="textsm" className="text-error-500">
                            {loginError}
                          </Typography>
                        )}
                      </div>
                    </div>

                    {/* <div className="flex justify-end">
                      <Typography variant="textsm" className="text-primary-700">
                        Forgot password?
                      </Typography>
                    </div> */}
                  </div>

                  <div className="my-8">
                    <Button
                      isSubmitting={isSubmitting}
                      type="submit"
                      title="Sign in"
                      fullwidth
                      disabled={!email || !password || isSubmitting}
                      onClick={handleSignIn}
                    />
                  </div>
                  <div className="flex gap-1 justify-center">
                    <Typography variant="textsm" className="text-grey-400">
                      Are you new here ?
                    </Typography>
                    <div onClick={() => changeView(EFormInView.REGISTER)} className="cursor-pointer">
                      {" "}
                      <Typography variant="textsm" className="text-primary-700" weight="medium">
                        Create Account
                      </Typography>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
          {formInView === EFormInView.REGISTER && (
            <>
              <div className="p-6 flex flex-col gap-8">
                <Typography variant="displayxs" className="text-shade-black text-left" weight="bold">
                  Create your account
                </Typography>
                <form className="">
                  <div className="flex flex-col gap-6">
                    <div className="relative rounded-md">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <img className="text-grey-500 sm:text-sm" alt="search" src={person_icon} />
                      </div>

                      <input
                        className="px-3 pl-10 py-4 bg-white border shadow-sm border-grey-300 placeholder-grey-500 focus:outline-none focus:border-primary-200 focus:ring-primary-200 block w-full rounded-md sm:text-sm focus:ring-1"
                        placeholder="Enter full name"
                        type="text"
                        onChange={(e) => setFullName(e.target.value)}
                      />
                    </div>
                    <div className="relative rounded-md">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <img className="text-grey-500 sm:text-sm" alt="search" src={email_icon} />
                      </div>

                      <input
                        className="px-3 pl-10 py-4 bg-white border shadow-sm border-grey-300 placeholder-grey-500 focus:outline-none focus:border-primary-200 focus:ring-primary-200 block w-full rounded-md sm:text-sm focus:ring-1"
                        placeholder="Enter your email"
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="relative rounded-md">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <img className="text-grey-500 sm:text-sm" alt="search" src={password_lock_icon} />
                      </div>

                      <input
                        className="px-3 pl-10 py-4 bg-white border shadow-sm border-grey-300 placeholder-grey-500 focus:outline-none focus:border-primary-200 focus:ring-primary-200 block w-full rounded-md sm:text-sm focus:ring-1"
                        placeholder="Enter your password"
                        autoComplete="current-password"
                        type={showPassword ? "text" : "password"}
                        onChange={(e) => setPassword(e.target.value.trim())}
                        onKeyDown={handleKeyPress}
                      />
                      <button type="button" className="absolute right-0 top-0 mt-3 mr-4 p-1 text-gray-500" onClick={togglePasswordVisibility}>
                        <img src={view_password_icon} className="text-grey-500 sm:text-sm" alt="view password" />
                      </button>
                    </div>
                  </div>

                  {/* <div className="flex justify-start mt-2">
                  <Typography variant="textsm" className="text-grey-500">
                    Password should be at least 12 characters
                  </Typography>
                </div> */}

                  <div className="my-8">
                    <Button
                      isSubmitting={isSubmitting}
                      type="submit"
                      title="Continue"
                      fullwidth
                      disabled={!email || !password || !fullName}
                      onClick={handleRegister}
                    />
                  </div>
                  <div className="flex gap-1 justify-center">
                    <Typography variant="textsm" className="text-grey-400">
                      Have an account ?
                    </Typography>
                    <div onClick={() => changeView(EFormInView.LOGIN)} className="cursor-pointer">
                      {" "}
                      <Typography variant="textsm" className="text-primary-700" weight="medium">
                        Sign In
                      </Typography>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
          {formInView === EFormInView.COMPLETE && (
            <div className="flex flex-col gap-12">
              <div className="flex flex-col items-center justify-center gap-4">
                <img src={creation_success_icon} alt="successul account creation" className="w-30 h-30" />

                <div className="item-center">
                  <Typography variant="displayxs" className="text-shade-black text-center !font-bold">
                    Account creation successful
                  </Typography>
                </div>
              </div>
              <Button title="Sign In" color="primary" fullwidth onClick={() => changeView(EFormInView.LOGIN)} />
            </div>
          )}
          {/* {formInView === EFormInView.COMPLETE && (
          <div className="flex flex-col gap-8">
            <div className="flex flex-col items-center justify-center gap-3">
              <Typography variant="displayxs" className="text-shade-black text-center !font-bold">
                Check your inbox
              </Typography>
              <div className="flex flex-col gap-1">
                <Typography variant="textsm" className="text-grey-500 text-center !font-bold">
                  {`We have sent an email to ${email}.`}
                </Typography>
                <Typography variant="textsm" className="text-grey-500 text-center !font-bold">
                  Click the link to verify your account.
                </Typography>
              </div>
            </div>
            <div className="flex flex-col gap-4 items-center">
              <div className="flex items-center">
                <Button title="Open Email" color="info" />
              </div>
              <div className="flex items-center">Resend Email</div>
            </div>
          </div>
        )} */}
        </div>
      </div>
      <div className="h-full p-6">
        <BackgroundImage src={auth_placeholder_image} alt="background image" />
      </div>
    </div>
  );
}
