import { amazon_s3, file_icon, folder_icon, password_lock_icon, view_password_icon } from "assets/svg";
import classNames from "classnames";
import { Button } from "components/shared/Button";
import Typography from "components/shared/Typography";
import { queryClient } from "index";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useDataSourceFromS3, useFetchDataSourceFromS3 } from "services";
import { FileTypes, IAmazonS3Payload, ISeparatorEnumValue, ModelInView, S3ModelInView } from "types";
import { SlArrowRight } from "react-icons/sl";
import { setCurrentFolderName } from "utils";

interface IAmazonS3FormProps {
  setModalInView: (value: ModelInView) => void;
  selectedFileType: string;
  report_id?: string;
  folder_id?: string;
  folder_name?: string;
  onClose?: () => void;
}

interface S3Object {
  name: string;
  location: string;
  size: string;
  content_type: "file" | "folder";
  last_modified: string;
}

export const AmazonS3 = (props: IAmazonS3FormProps) => {
  const { setModalInView, selectedFileType, report_id, folder_id, folder_name } = props;

  const navigate = useNavigate();
  const [separator, setSeparator] = useState<ISeparatorEnumValue>();

  const [accessKey, setAccessKey] = useState<string>("");
  const [accessSecret, setAccessSecret] = useState<string>("");
  const [bucketName, setBucketName] = useState<string>("");
  const [view, setView] = useState<S3ModelInView>(S3ModelInView.ACCESS);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const accessButtonValidation = !accessKey || isSubmitting || !accessSecret || !bucketName;

  const [showSecret, setShowSecret] = useState(false);

  const [currentFolder, setCurrentFolder] = useState("");
  const [s3ObjectsFolder, setS3ObjectsFolder] = useState<S3Object[]>([]);

  const toggleSecretVisibility = () => {
    setShowSecret(!showSecret);
  };

  const { fetchFromS3, isPending } = useFetchDataSourceFromS3();

  const { uploadFromS3 } = useDataSourceFromS3();

  const customId = "custom-toast-id";

  const [selectedDataSources, setSelectedDataSources] = useState<string[]>([]);

  const fetchFolderObjects = async (data_source: S3Object) => {
    setIsSubmitting(true);

    const data: IAmazonS3Payload = {
      access_key: accessKey,
      access_secret: accessSecret,
      bucket_name: bucketName,
      folder: (data_source && data_source.location) || "",
    };

    fetchFromS3(data, {
      onError: (error, variables, context) => {
        toast.error("Error fetching data source", {
          position: "top-center",
          toastId: customId,
          autoClose: 1500,
          theme: "colored",
        });
        setIsSubmitting(false);
      },
      onSuccess: (data, variables, context) => {
        const filterFolders = data?.data?.objects[0]?.location.split("/");

        const folderName = filterFolders[filterFolders.length - 2];
        const previousFolder = filterFolders[filterFolders.length - 3];
        setS3ObjectsFolder(data?.data?.objects);
        setIsSubmitting(false);
      },
    });
  };
  const handleDataSourceSelection = async (item: string) => {
    try {
      let checkedReportState = [...selectedDataSources];
      if (checkedReportState.includes(item)) checkedReportState = checkedReportState.filter((id) => id !== item);
      else checkedReportState = checkedReportState.concat(item);
      setSelectedDataSources(checkedReportState);
    } catch (e) {
      console.error(e);
    }
  };

  const disableButton = view === S3ModelInView.ACCESS ? accessButtonValidation : selectedDataSources.length === 0 || isSubmitting;

  const getS3Access = async () => {
    setIsSubmitting(true);
    const data: IAmazonS3Payload = {
      access_key: accessKey,
      access_secret: accessSecret,
      bucket_name: bucketName,
      folder: "",
    };

    fetchFromS3(data, {
      onError: (error, variables, context) => {
        const errorMessage = (error?.message as string) || "Error fetching data source";

        toast.error(errorMessage, {
          position: "top-center",
          toastId: customId,
          autoClose: 1500,
          theme: "colored",
        });
        setIsSubmitting(false);
      },
      onSuccess: (data, variables, context) => {
        const filterFolders = data?.data?.objects[0]?.location.split("/");
        const folderName = filterFolders[filterFolders.length - 2];

        setS3ObjectsFolder(data?.data?.objects);
        setView(S3ModelInView.FOLDER);
        setIsSubmitting(false);
      },
    });
  };

  const getData = async (e: any) => {
    e.preventDefault();
    setIsSubmitting(true);
    const data = {
      access_key: accessKey,
      access_secret: accessSecret,
      bucket_name: bucketName,
      objects: [...selectedDataSources],
      parent_id: folder_id,
      report_id,
    };
    uploadFromS3(data, {
      onError: (error, variables, context) => {
        toast.error("Error uploading data source", {
          position: "top-center",
          toastId: customId,
          autoClose: 1500,
          theme: "colored",
        });
        setIsSubmitting(false);
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({ queryKey: ["datasources"] });

        if (folder_id) {
          queryClient.invalidateQueries({ queryKey: ["datasources-folder", folder_id] });
          navigate(`/data-sources/folder/${folder_id}?name=${folder_name}`);
        } else if (report_id) {
          queryClient.invalidateQueries({ queryKey: ["report-datasources", report_id] });
          window.history.back();
        } else {
          navigate(`/data-sources`);
        }

        toast.success("File uploaded succesfully", {
          position: "top-center",
          toastId: customId,
          autoClose: 1000,
          theme: "colored",
        });
        setIsSubmitting(false);
      },
    });
  };

  const [currentPath, setCurrentPath] = useState<string>("");
  const [endFilteredObjects, setendFilteredObjects] = useState<S3Object[]>([]);

  const organizedObjects = useMemo(() => {
    const folderStructure: { [key: string]: S3Object[] } = {};

    // Group objects by their parent folder
    s3ObjectsFolder.forEach((obj) => {
      // Remove trailing slash for consistent processing
      const cleanLocation = obj.location.replace(/\/$/, "");

      // Determine the parent folder
      const parentFolder = cleanLocation.split("/").slice(0, -1).join("/");

      if (!folderStructure[parentFolder]) {
        folderStructure[parentFolder] = [];
      }
      folderStructure[parentFolder].push(obj);
    });

    return folderStructure;
  }, [s3ObjectsFolder]);

  const currentFolderContents = useMemo(() => {
    return organizedObjects[currentPath] || organizedObjects[""] || s3ObjectsFolder.filter((obj) => !obj.location.includes("/"));
  }, [currentPath, organizedObjects]);

  // Navigate into a folder
  const navigateToFolder = (folderName: string) => {
    setCurrentPath(currentPath ? `${currentPath}/${folderName}` : folderName);
  };

  // Go back to parent folder
  const navigateBack = () => {
    if (currentPath === "") {
      setView(S3ModelInView.ACCESS);
    }

    setCurrentFolder(currentPath.split("/")[0] || "");
    setCurrentPath(currentPath.split("/").slice(0, -1).join("/"));
  };



  const emptyBucket = (currentFolderContents as S3Object[]) && currentFolderContents.length === 0;

  // const dataSources = currentFolderContents.filter(
  //   (datasource) => datasource.name.split(".")[1] !== "csv" || datasource.name.split(".")[1] !== "csv"
  // );

  const dataSources = currentFolderContents;

  return (
    <div>
      <div className="flex justify-center items-center px-6 pb-4 pt-6 mb-6">
        {view !== S3ModelInView.ACCESS ? (
          <div className="flex items-center gap-2">
            <img src={amazon_s3} alt="data source" className="h-8 w-8" />
            <Typography variant="textxl" className="font-semibold">
              {currentFolder || bucketName}
            </Typography>
          </div>
        ) : (
          <div className="flex items-center gap-2">
            <img src={amazon_s3} alt="data source" className="h-8 w-8" />
            <Typography variant="textxl" className="font-semibold">
              Add S3 Bucket Source
            </Typography>
          </div>
        )}{" "}
      </div>
      {view === S3ModelInView.ACCESS && (
        <form className="flex flex-col gap-8 overflow-auto" onSubmit={getS3Access}>
          <div>
            <div className="flex flex-col gap-1 px-6 mt-4">
              <Typography variant="textsm" className="text-grey-900 text-left !font-medium">
                Access Key
              </Typography>
              <input
                className="h-[44px] mt-[2px] px-3 py-2 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                placeholder="Access Key"
                onChange={(event) => {
                  setAccessKey(event.target.value);
                }}
              />
            </div>
            <div className="flex flex-col gap-1 px-6 mt-4">
              <Typography variant="textsm" className="text-grey-900 text-left !font-medium">
                Access Secret
              </Typography>

              <div className="relative rounded-md">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <img className="text-grey-500 sm:text-sm" alt="search" src={password_lock_icon} />
                </div>

                <input
                  className="h-[44px] px-3 pl-10 py-auto bg-white border shadow-sm border-grey-300 placeholder-grey-500 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                  placeholder="Access Secret"
                  autoComplete="current-password"
                  type={showSecret ? "text" : "password"}
                  onChange={(event) => {
                    setAccessSecret(event.target.value);
                  }}
                />
                <button type="button" className="absolute right-0 top-[12px] mr-4 p-auto text-gray-500" onClick={toggleSecretVisibility}>
                  <img src={view_password_icon} className="text-grey-500 sm:text-sm" alt="view password" />
                </button>
              </div>
            </div>
            <div className="flex flex-col gap-1 px-6 mt-4">
              <Typography variant="textsm" className="text-grey-900 text-left !font-medium">
                Bucket Name
              </Typography>
              <input
                className="h-[44px] mt-[2px] px-3 py-2 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                placeholder="Bucket Name"
                onChange={(event) => {
                  setBucketName(event.target.value);
                }}
              />
            </div>
            {selectedFileType === FileTypes.CSV && (
              <>
                <div className="flex flex-col gap-1 px-6 mt-4">
                  <Typography variant="textsm" className="text-grey-900 text-left !font-medium">
                    Select a Separator
                  </Typography>

                  <div className="flex gap-3 ">
                    <div
                      className={classNames(
                        "w-[98px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 flex items-center justify-center hover:cursor-pointer",
                        {
                          "bg-primary-50": separator === ISeparatorEnumValue.COMMA,
                        }
                      )}
                      onClick={() => {
                        setSeparator(ISeparatorEnumValue.COMMA);
                      }}
                    >
                      <div className="flex justify-center gap-2 items-center w-full p-2">
                        <Typography variant="textsm" className="text-grey-500">
                          Comma
                        </Typography>
                        <Typography variant="textsm" className="text-grey-500">
                          (,)
                        </Typography>
                      </div>
                    </div>
                    <div
                      className={classNames(
                        "w-[98px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200  flex items-center justify-center hover:cursor-pointer",
                        {
                          "bg-primary-50": separator === ISeparatorEnumValue.TAB,
                        }
                      )}
                      onClick={() => {
                        setSeparator(ISeparatorEnumValue.TAB);
                      }}
                    >
                      <div className="flex justify-center gap-2 items-center w-full p-2">
                        <Typography variant="textsm" className="text-grey-500">
                          Tab
                        </Typography>
                        <Typography variant="textsm" className="text-grey-500">
                          (\t)
                        </Typography>
                      </div>
                    </div>
                    <div
                      className={classNames(
                        "w-[98px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200  flex items-center justify-center hover:cursor-pointer",
                        {
                          "bg-primary-50": separator === ISeparatorEnumValue.SEMICOLON,
                        }
                      )}
                      onClick={() => {
                        setSeparator(ISeparatorEnumValue.SEMICOLON);
                      }}
                    >
                      <div className="flex justify-center gap-2 items-center w-full p-2">
                        <Typography variant="textsm" className="text-grey-500">
                          Semicolon
                        </Typography>
                        <Typography variant="textsm" className="text-grey-500">
                          (;)
                        </Typography>
                      </div>
                    </div>

                    <div
                      className={classNames(
                        "w-[98px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200  flex items-center justify-center hover:cursor-pointer",
                        {
                          "bg-primary-50": separator === ISeparatorEnumValue.PIPE,
                        }
                      )}
                      onClick={() => {
                        setSeparator(ISeparatorEnumValue.PIPE);
                      }}
                    >
                      <div className="flex justify-center gap-2 items-center w-full p-2">
                        <Typography variant="textsm" className="text-grey-500">
                          Pipe
                        </Typography>
                        <Typography variant="textsm" className="text-grey-500">
                          (|)
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="flex justify-between items-center px-6 pb-4 pt-4 mt-6">
            <Button type="button" title="Go back" color="info" onClick={() => setModalInView(ModelInView.SOURCE)} />
            <Button disabled={disableButton} isSubmitting={isSubmitting} type="submit" title="Continue" onClick={getS3Access} />
          </div>{" "}
        </form>
      )}
      {view === S3ModelInView.FOLDER && (
        <form className="flex flex-col gap-8 overflow-auto">
          {emptyBucket && (
            <div className="flex py-10 justify-center items-center w-full">
              <Typography variant="displaysm" weight="semibold" className="text-shade-black text-center">
                No files available in this folder
              </Typography>
            </div>
          )}

          <div className="flex flex-col pb-4 gap-2 w-full">
            {!emptyBucket && dataSources.length > 0 && (
              <div className="my-4 px-6">
                {dataSources?.map((data_source: S3Object, index) => (
                  <div
                    className="w-full flex items-center justify-between  hover:bg-grey-75 px-1 py-3 rounded-md"
                    key={`${data_source.name}${Math.random()}`}
                  >
                    <div className="flex items-center gap-3">
                      <img src={data_source.content_type === "folder" ? folder_icon : file_icon} alt="pdf" className="h-6 w-6" />
                      <Typography variant="body2" className="font-medium">
                        {data_source.name}
                      </Typography>
                    </div>
                    <div className="ml-4 flex items-center gap-2" key={`${data_source.name}${Math.random()}`}>
                      <input
                        type="checkbox"
                        id="radioColor"
                        className="mr-2 h-5 w-5 hover:cursor-pointer"
                        checked={!!selectedDataSources?.find((id: any) => id === (data_source.location as string))}
                        onChange={() => handleDataSourceSelection(data_source.location as string)}
                      />
                      {data_source.content_type === "folder" ? (
                        <div
                          className="w-6 h-6 rounded-md hover:cursor-pointer flex items-center"
                          key={`${data_source.name}${Math.random()}`}
                          onClick={() => {
                            setCurrentFolder(setCurrentFolderName(data_source.location));
                            navigateToFolder(data_source.name);
                            // fetchFolderObjects(data_source);
                          }}
                        >
                          <SlArrowRight />
                        </div>
                      ) : (
                        <div className="w-6 h-6 rounded-md flex items-center"></div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="flex justify-between items-center pt-4 mt-6 px-6">
              <Button type="button" disabled={isSubmitting} title="Go back" color="info" onClick={() => navigateBack()} />
              <Button disabled={disableButton} isSubmitting={isSubmitting} type="submit" title="Save" onClick={(e: any) => getData(e)} />
            </div>
          </div>
        </form>
      )}
    </div>
  );
};
