import { Button } from "components/shared/Button";
import Typography from "components/shared/Typography";
import { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { IDataSource } from "types";
import { fuzzySearch, getIcon } from "utils";

interface IDataSourceModal {
  data: Partial<IDataSource>[];
  setRadioValue: (e: any) => void;
  value: any;
  onClose: (close: boolean) => void;
  selectedDataSources: any[];
  handleDataSelection: (data: Partial<IDataSource>) => void;
}

export default function EditDataSourcesModal(props: IDataSourceModal) {
  const { data, setRadioValue, onClose, value, selectedDataSources, handleDataSelection } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<Partial<IDataSource>[]>();

  useEffect(() => {
    if (searchTerm.length > 0) {
      setSearchResults(fuzzySearch("name", searchTerm, data));
    }
  }, [searchTerm]);

  const source = searchResults && searchResults?.length > 0 ? searchResults : data;

  const displayDataSource = source?.length > 0;

  const displayNoDataSource = source?.length === 0;

  return (
    <div className="flex bg-[#FFF] flex-col rounded-md w-auto">
      <div className="flex justify-between items-center px-6 pb-4 pt-6  border-b-[1px] border-b-grey-100">
        <Typography variant="textxl" className="font-semibold">
          Choose File Add Source
        </Typography>

        <div onClick={() => onClose(false)} className="hover:cursor-pointer h-6 w-6">
          <IoMdClose className="w-full h-full" />
        </div>
      </div>
      {displayNoDataSource && (
        <div className="flex justify-center items-center h-56 px-8">
          <Typography variant="displaysm" className="text-grey-500">
            No Data Source Found
          </Typography>{" "}
        </div>
      )}
      {displayDataSource && (
        <>
          {/* <div className="mt-3">
            <div className="relative rounded-md px-6 pt-2">
              <div className="pointer-events-none absolute inset-y-0 left-6 flex items-center pl-3">
                <img className="text-grey-500 sm:text-sm" alt="search" src={search_icon} />
              </div>

              <input
                className="h-12 px-3 pl-10 py-2 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-primary-200 focus:ring-primary-200 block w-full rounded-md sm:text-sm focus:ring-1"
                placeholder="Search data source"
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                }}
              />
            </div>
          </div> */}
          <div className="px-6 py-4 flex gap-4 flex-col w-[470px]  h-[350px] overflow-auto">
            {source?.map((data_source: Partial<IDataSource>, index) => (
              <div key={data_source.id} className="flex justify-between items-center gap-4">
                <div className="flex items-center gap-2">
                  <img src={getIcon(data_source.type as string)} alt="pdf" className="h-10 w-10" />
                  <Typography variant="body2" className="font-medium">
                    {data_source.name}
                  </Typography>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="radioColor"
                    name="fileType"
                    checked={!!selectedDataSources?.find((data: any) => data.id === (data_source.id as string))}
                    // checked={!!selectedDataSources.includes(data_source.id)}
                    onChange={() => handleDataSelection(data_source as Partial<IDataSource>)}
                    className="!w-4 !h-4 hover:cursor-pointer"
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      <div className="flex justify-between items-center">
        <div className="flex justify-end items-center px-6 mb-6 mt-4">
          <Button color="info" type="button" title="Cancel" onClick={() => onClose(false)} />
        </div>{" "}
        <div className="flex justify-end items-center px-6 mb-6 mt-4">
          <Button type="button" title="Add" onClick={() => onClose(false)} />
        </div>{" "}
      </div>
    </div>
  );
}
