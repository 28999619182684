import { useEffect, useState } from "react";
import { useFetchDataSources } from "services";
import { Modal, Pagination } from "components/shared";
import { IDataSource } from "types";
import Loading from "components/shared/Loading";
import Typography from "components/shared/Typography";
import classNames from "classnames";
import DataSourceComponent from "./DataSourceComponent";
import { AddDataSourceModal } from "./modals";
import EmptyDatasources from "./EmptyDatasources";

interface IListDataSourcesProps {
  setDataSourcesTotal: Function;
}
export default function ListDataSources(props: IListDataSourcesProps) {
  const { setDataSourcesTotal } = props;
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [dataTotal, setDataTotal] = useState(0);

  const trigger = dataTotal > 0;

  const query = {
    page: String(currentPage),
    triggerFetch: trigger || false,
  };

  const { data, isFetching, error } = useFetchDataSources(query);
  const folderDataSources = data?.data?.data_sources as Partial<IDataSource>[];

  const displayNoDataSourcesAvailable = data && data.data.data_sources.length === 0 && !isFetching;

  const total = data?.data.total as number;

  useEffect(() => {
    setDataSourcesTotal(total);
    setDataTotal(total);
  }, [data]);

  const dataSources = data?.data?.data_sources as Partial<IDataSource>[];

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [createFolderModalOpen, setCreateFolderModalOpen] = useState<boolean>(false);

  const displayDataSources = dataSources && dataSources.length > 0 && !isFetching;

  return (
    <div>
      {createFolderModalOpen && (
        <Modal
          onClose={() => setCreateFolderModalOpen(false)}
          open={modalOpen}
          variant="full"
          children={<AddDataSourceModal onClose={() => setCreateFolderModalOpen(false)} />}
        />
      )}
      {error && (
        <div className="mt-40 w-full flex justify-center items-center">
          <Typography variant="displaysm" weight="semibold" className="text-shade-black text-center">
            There was an unexpected error while retrieving data sources
          </Typography>
        </div>
      )}
      {isFetching && (
        <div className={classNames("h-full mt-2 w-[700px]")}>
          <Loading numberOfLoaders={5} className="bg-grey-200 !h-20" />
        </div>
      )}
      {displayNoDataSourcesAvailable && <EmptyDatasources title={"New Source"} />}

      {displayDataSources && (
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-5">
            {folderDataSources?.map((dataSource) => (
              <div key={dataSource.id}>
                <DataSourceComponent
                  name={dataSource.name as string}
                  id={dataSource.id as string}
                  updated={dataSource.updated as string}
                  type={dataSource.type as string}
                  origin={dataSource.origin as string}
                  status={dataSource.status as string}
                />
              </div>
            ))}
            {total && total > 10 && (
              <div className="mt-2">
                <Pagination onChangePage={setCurrentPage} pageSize={10} currentPage={currentPage} total={total || 0} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
