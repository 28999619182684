import { arrow } from "@floating-ui/react";
import Typography from "../shared/Typography";
import { getIcon } from "utils";
import { FaRightLong } from "react-icons/fa6";
import { arrow_right_icon, loading_processing_icon, upload_icon, upload_local_icon } from "assets/svg";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "providers/AppContext";
import { useContext } from "react";
import { UploadStatusEnum } from "types";

interface IDataSourceComponent {
  name: string;
  id: string;
  updated: string;
  type: string;
  sample?: true;
  origin: string;
  status: string;
}
export default function DataSourceComponent(props: IDataSourceComponent) {
  const { name, id, updated, type, sample, origin, status } = props;

  const navigate = useNavigate();

  const { togglePdfModal, setDataSource, startDataSourceDownload, setPage, setDataSourceType, isPdfOpen, setDataSourceName } = useContext(AppContext);

  const date = new Date(updated).toLocaleDateString();
  const time = new Date(updated).toLocaleTimeString();

  const icon = getIcon(type as string);

  const toggleDropdown = () => {
    setDataSource(id as string);
    togglePdfModal();
    startDataSourceDownload();
    setDataSourceType(type);
    setDataSourceName(name);
  };

  const fileOrigin = origin === "local" ? "Local File" : "";

  const gotToFolderDetails = () => {
    if (type !== "folder") return;
    navigate(`folder/${id}?name=${name}`);
  };
  return (
    <div
      className="flex justify-between items-center cursor-pointer w-full py-4 px-3 border-[1px] border-grey-200 rounded-lg hover:bg-grey-100 gap-8 max-w-[700px]"
      onClick={gotToFolderDetails}
    >
      <div className="flex gap-3 items-center">
        {!sample && <img src={icon} alt="pdf" className="w-[60px] h-[60px] rounded-lg" />}
        <div className="flex gap-2 justify-center flex-col items-start w-full">
          <Typography variant="body2" className="text-shade-black font-semibold">
            {name}
          </Typography>

          <div className="flex gap-1 items-center">
            {UploadStatusEnum.PROCESSING === status && (
              <div className="flex items-center gap-1">
                <img src={loading_processing_icon} alt="processing" className="h-4 w-6 animate-spin" />
                <Typography variant="textsm" className="text-grey-500 font-regular">
                  Processing...
                </Typography>
              </div>
            )}
            {UploadStatusEnum.COMPLETE === status && (
              <div className="flex items-center gap-1">
                <Typography variant="textsm" className="text-grey-500 font-regular">
                  Uploaded from:
                </Typography>
                <img src={getIcon(`${origin}-file`)} alt="upload" className="h-4 w-6" />
                <Typography variant="textsm" className="text-grey-500 font-regular">
                  {fileOrigin} - last updated {date} {time}
                </Typography>
              </div>
            )}
            {UploadStatusEnum.FAILED === status && (
              <div className="flex items-center gap-1">
                <Typography variant="textsm" className="text-error-500 font-regular">
                  Failed
                </Typography>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="">
        {type === "folder" && status === UploadStatusEnum.COMPLETE && (
          <Link to={`folder/${id}?name=${name}`} className="flex items-center gap-2 justify-end  w-[130px]">
            <Typography variant="textsm" className="text-grey-800 font-regular">
              View Folder
            </Typography>
            <img src={arrow_right_icon} alt="arrow" className="w-[20px] h-[20px]" />
          </Link>
        )}

        {(type === "pdf" || type === "docx") && status === UploadStatusEnum.COMPLETE && (
          <div className="flex items-center gap-2 justify-end  w-[130px]" onClick={toggleDropdown}>
            <Typography variant="textsm" className="text-grey-800 font-regular">
              {`Preview ${type}`}
            </Typography>
            <img src={arrow_right_icon} alt="arrow" className="w-[20px] h-[20px]" />
          </div>
        )}
      </div>
    </div>
  );
}
