import { briefcase_icon, delete_icon, file_icon_upload, green_checkmark_icon, red_trash_icon } from "assets/svg";
import React, { ChangeEvent, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import Typography from "./Typography";
import { toast } from "react-toastify";
import classNames from "classnames";
import { LiaUploadSolid } from "react-icons/lia";
import { FileTypes } from "types";
import { Button } from "./Button";
import { Modal } from "./Modal";
import FolderUpload from "components/Datasources/modals/FolderUpload";

export const DOCUMENT_TYPES = [
  "application/pdf",
  "text/csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const ACCEPTED_FILE_TYPE = {
  pdf: { "application/pdf": [".pdf"] },
  csv: { "text/csv": [".csv"] },
  docx: { "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"] },
  xlsx: { "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"] },
};

export enum IDropzoneType {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}
interface IDropzone {
  setFiles: Function;
  dropZoneType: IDropzoneType;
  fileType?: FileTypes;
  files?: File[];
  setFolderName: Function;
}

interface FileDetails {
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
}

export function DropZone(props: IDropzone) {
  const { setFiles, dropZoneType, fileType, files, setFolderName } = props;

  const [preview, setPreview] = useState<File[]>([]);
  const onDrop = useCallback((acceptedFiles: File[]) => {
    const customId = "custom-toast-id";

    const files = acceptedFiles;

    files.forEach((file) => {
      if (file && file.size > 5000000) {
        toast.error("File size should be less than 50MB", {
          position: "top-center",
          toastId: customId,
          autoClose: 3000,
          theme: "colored",
        });
        return;
      }

      if (file && !DOCUMENT_TYPES.includes(file.type)) {
        toast.error("File type not supported", {
          position: "top-center",
          toastId: customId,
          autoClose: 3000,
          theme: "colored",
        });
        return;
      }
    });

    const filterFileBySize = files.filter((file) => file.size <= 5000000);

    if (files) {
      setFiles(filterFileBySize);
      setPreview(filterFileBySize);
      setValidFilesinFolder([]);
      setInvalidFilesinFolder([]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "text/csv": [".csv"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    },
    noClick: true,
  });

  const deleteFile = (filter: number) => {
    setPreview(preview.filter((file: File, index) => index !== filter));
  };

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);

  const [validFilesinFolder, setValidFilesinFolder] = useState<File[]>([]);
  const [invalidFilesinFolder, setInvalidFilesinFolder] = useState<File[]>([]);

  const deleteValidFile = (filter: number) => {
    setValidFilesinFolder(validFilesinFolder.filter((file: File, index) => index !== filter));
  };

  const handleFolderUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;

    if (!fileList) {
      setError("No files were selected.");
      return;
    }

    const allowedExtensions = [".pdf", ".docx"];
    const validFiles: FileDetails[] = [];
    const invalidFiles: string[] = [];

    Array.from(fileList).forEach((file) => {
      const extension = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();

      if (allowedExtensions.includes(extension)) {
        validFiles.push(file);
      } else {
        invalidFiles.push(file.name);
      }
    });

    setFolderName(validFiles && (validFiles[0]?.webkitRelativePath as unknown as string).split("/")[0]);
    setPreview([]);
    setFiles(validFiles);
    setValidFilesinFolder(validFiles as any);
    setInvalidFilesinFolder(invalidFiles as any);

    if (invalidFiles.length > 0) {
      setError(`The following files were excluded: ${invalidFiles.join(", ")}`);
    } else {
      setError(null);
    }
  };

  return (
    <div>
      {modalOpen && <Modal onClose={() => setModalOpen(false)} open={modalOpen} variant="full" children={<FolderUpload />} />}
      <div
        {...getRootProps()}
        className={classNames("w-full flex flex-col gap-2 border-[1px] px-8 py-14 border-dashed border-grey-200 rounded-md hover:cursor-pointer", {
          "h-auto": dropZoneType === IDropzoneType.SMALL,
          "h-40": dropZoneType === IDropzoneType.LARGE,
        })}
      >
        <input {...getInputProps()} />
        {isDragActive && (
          <div className="flex flex-col justify-between items-center w-full gap-6">
            <img src={file_icon_upload} alt="upload file" />

            <div className="flex items-center gap-2 flex-col">
              <Typography variant="textsm" className="font-medium text-shade-black">
                Choose a file or drag and drop it here
              </Typography>
              <Typography variant="textsm" className="font-regular text-grey-500">
                PDF, DOCX, CSV and XLSX formats, up to 50MB
              </Typography>
            </div>
          </div>
        )}{" "}
        {!isDragActive && (
          <div className="flex flex-col justify-between items-center w-full gap-6">
            <img src={file_icon_upload} alt="upload file" />

            <div className="flex items-center gap-2 flex-col">
              <Typography variant="textsm" className="font-medium text-shade-black">
                Choose a file or drag and drop it here
              </Typography>
              <Typography variant="textsm" className="font-regular text-grey-500">
                PDF, DOCX, CSV and XLSX formats, up to 50MB
              </Typography>
            </div>

            <div className="flex gap-2 items-center">
              <Button title="Upload Data Sources" variant="outlined" onClick={open} color="soft-primary" weigth="regular" />

              <div>
                <input
                  type="file"
                  ref={(input) => input && input.setAttribute("webkitdirectory", "")}
                  multiple
                  onChange={handleFolderUpload}
                  accept=".pdf,.docx"
                  id="folder-upload"
                />
                <label
                  htmlFor="folder-upload"
                  className="rounded-[4px] flex items-center cursor-pointer text-primary-700 border-primary-700 border-[1px] py-2 px-3 bg-primary-50 hover:bg-primary-100 w-fit"
                >
                  Upload Folder
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
      {preview && preview.length > 0 && files && files.length > 0 && (
        <div className="flex flex-col gap-4 mt-4">
          <Typography variant="textlg" className="text-sm text-start">
            Preview File for upload
          </Typography>
          {preview.map((file: File, index) => (
            <div className="border-[1px] rounded-md border-grey-100 hover:bg-grey-75 h-full w-full flex items-center justify-between p-3" key={index}>
              <div className="flex items-center gap-3">
                <img src={green_checkmark_icon} alt="chcekmark" />
                <Typography variant="textsm" className="font-regular text-shade-black">
                  {file?.name}
                </Typography>
              </div>
              <img
                className="hover:cursor-pointer"
                src={delete_icon}
                alt="close"
                onClick={() => {
                  deleteFile(index);
                }}
              />
            </div>
          ))}
        </div>
      )}

      {validFilesinFolder && validFilesinFolder.length > 0 && files && files.length > 0 && (
        <div className="flex flex-col gap-4 mt-4">
          <Typography variant="textlg" className="text-sm text-start text-success-500">
            Preview supported file format in folder
          </Typography>
          {validFilesinFolder.map((file: File, index) => (
            <div className="border-[1px] rounded-md border-grey-100 hover:bg-grey-75 h-full w-full flex items-center justify-between p-3" key={index}>
              <div className="flex items-center gap-3">
                <img src={green_checkmark_icon} alt="chcekmark" />
                <Typography variant="textsm" className="font-regular text-shade-black">
                  {file?.name}
                </Typography>
              </div>
              <img
                className="hover:cursor-pointer"
                src={delete_icon}
                alt="close"
                onClick={() => {
                  deleteValidFile(index);
                }}
              />
            </div>
          ))}
        </div>
      )}

      {validFilesinFolder && validFilesinFolder.length > 0 && files && files.length > 0 && (
        <div className="flex flex-col gap-4 mt-4">
          <Typography variant="textlg" className="text-sm text-start text-error-500">
            Preview unsupported file format in folder
          </Typography>
          {invalidFilesinFolder.map((file, index) => (
            <div className="border-[1px] rounded-md border-grey-100 hover:bg-grey-75 h-full w-full flex items-center justify-between p-3" key={index}>
              <div className="flex items-center gap-3">
                <Typography variant="textsm" className="font-regular text-shade-black">
                  {file as any}
                </Typography>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

// TODO
// UPLOAD FOLDER
//
//
