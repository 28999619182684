import { queryClient } from "index";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useUploadDataSource } from "services";
import { DataSources, FileTypes, ISeparatorEnumValue } from "types";

interface FileDetails {
  name: string;
  size: number;
  type: string;
}

const FolderUpload: React.FC = (setFolders: any) => {
  const [files, setFiles] = useState<FileDetails[]>([]);
  const [error, setError] = useState<string | null>(null);

  const [selectedFileType, setSelectedFileType] = useState<FileTypes>(FileTypes.PDF);
  const [selectedDataSource, setSelectedDataSource] = useState<DataSources>(DataSources.LOCAL);
  const [fileName, setFileName] = useState<string>();
  const [separator, setSeparator] = useState<ISeparatorEnumValue>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { uploadDatasource } = useUploadDataSource();

  const handleFolderUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;

    if (!fileList) {
      setError("No files were selected.");
      return;
    }

    const allowedExtensions = [".pdf", ".docx"];
    const validFiles: FileDetails[] = [];
    const invalidFiles: string[] = [];

    Array.from(fileList).forEach((file) => {
      const extension = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();

      if (allowedExtensions.includes(extension)) {
        // validFiles.push({
        //   name: file.name,
        //   size: file.size,
        //   type: file.type,
        // });
        validFiles.push(file);
      } else {
        invalidFiles.push(file.name);
      }
    });

    setFiles(validFiles);

    if (invalidFiles.length > 0) {
      setError(`The following files were excluded: ${invalidFiles.join(", ")}`);
    } else {
      setError(null);
    }
  };

  const formData = new FormData();
  const report_id = "";

  const handleUpload = async () => {
    setIsSubmitting(true);

    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i] as File);
    }
    //   formData.append("file", file as File);
    formData.append("data_source_name", "first folder" as string);
    formData.append("type", "folder");
    //  formData.append("separator", separator as ISeparatorEnumValue);
    if (report_id) {
      formData.append("report_id", report_id as string);
    }
    //   formData.append("type_class", structuredFileType as string);

    const customId = "custom-toast-id";

    uploadDatasource(formData, {
      onError: (error, variables, context) => {
        toast.error(error?.message || "Error creating Datasource", {
          position: "top-center",
          toastId: customId,
          autoClose: 2000,
          theme: "colored",
        });
        setIsSubmitting(false);
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({ queryKey: ["datasources"] });

        if (report_id) {
          queryClient.invalidateQueries({ queryKey: ["report-datasources", report_id] });
        }
        //onClose();

        toast.success("Datasource Created Successfully", {
          position: "top-center",
          toastId: customId,
          autoClose: 1000,
          theme: "colored",
        });
      },
    });
  };


  const uploadFiles = () => {
    // Replace this with your actual upload logic
    alert("Files uploaded successfully!");
    console.log("Files to upload:", files);
  };

  return (
    <div className="bg-shade-white p-4 rounded-md shadow-md ">
      <h2>Upload Folder</h2>
      <input
        type="file"
        ref={(input) => input && input.setAttribute("webkitdirectory", "")}
        multiple
        onChange={handleFolderUpload}
        accept=".pdf,.docx" // Only allow selection of PDF and CSV files
      />
      {error && <p style={{ color: "red" }}>{error}</p>}
      <div>
        <h3>Selected Files</h3>
        <ul>
          {files.map((file, index) => (
            <li key={index}>
              {file.name} ({(file.size / 1024).toFixed(2)} KB)
            </li>
          ))}
        </ul>
      </div>
      <button onClick={handleUpload} disabled={files.length === 0}>
        Upload Data Sources
      </button>
    </div>
  );
};

export default FolderUpload;
