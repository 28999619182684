import { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { FileTypes, IDataSource } from "types";
import { useCreateReport, useFetchDataSources } from "services";
import { convertArrayToObjectOfObjects, formikHelper, fuzzySearch, getIcon } from "utils";
import { Button } from "components";
import Loading from "components/shared/Loading";
import Typography from "components/shared/Typography";
import { csv_icon, delete_icon, pdf_icon, search_icon, xlsx_icon } from "assets/svg";
import { toast } from "react-toastify";
import { queryClient } from "index";
import { useNavigate } from "react-router";

interface ICreateReport {
  onClose: () => void;
}

interface ICreateReportPayload {
  title: string;
}
export function CreateReport(props: ICreateReport) {
  const { onClose } = props;

  const navigate = useNavigate();

  const query = {
    page: "1",
    status: "COMPLETE",
    triggerFetch: false,
  };
  const { data, error, isFetching } = useFetchDataSources(query);
  const { createReport } = useCreateReport();
  const [dataSourceFilter, setDataSourceFilter] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [dataSources, setDataSources] = useState<Array<Partial<IDataSource>>>([]);

  const CreateReportSchema = Yup.object().shape({
    title: Yup.string().min(2, "Too Short!").required("Required"),
  });

  const handleSubmit = async (values: any) => {
    setIsSubmitting(true);
    const { title } = values;

    const payload: any = {
      title,
      data_sources: convertArrayToObjectOfObjects(dataSources),
    };

    const customId = "custom-toast-id";

    createReport(payload, {
      onError: (error, variables, context) => {
        toast.error("Error creating report", {
          position: "top-center",
          toastId: customId,
          autoClose: 1500,
          theme: "colored",
        });
        setIsSubmitting(false);
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({ queryKey: ["reports"] });
        onClose();
        toast.success("Report Created Successfully", {
          position: "top-center",
          toastId: customId,
          autoClose: 1500,
          theme: "colored",
        });

        setIsSubmitting(false);
        navigate(`reports/${data?.data?.id}`);
      },
    });
  };

  const addDataSource = (id: string, name: string, type: string) => {
    const findDataSource = dataSources.find((data_source) => data_source.id === id);
    if (findDataSource) return;
    setDataSources((prev) => [...prev, { id, name, type }]);
  };

  const removeDataSource = (id: string) => {
    const filterDatasource = dataSources.filter((data_source) => data_source.id !== id);

    setDataSources(filterDatasource);
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<Partial<IDataSource>[]>();

  useEffect(() => {
    if (searchTerm.length > 0) {
      setSearchResults(fuzzySearch("name", searchTerm, data?.data.data_sources as Partial<IDataSource>[]));
    }
  }, [searchTerm]);

  const sources = searchResults && searchResults.length > 0 ? searchResults : data && data?.data.data_sources;

  const displayNoDataSourcesAvailable = sources && sources.length === 0;

  const displayDataSources = sources && sources.length > 0 && !isFetching;

  return (
    <div className="flex bg-[#FFF] h-[503px]  w-full  rounded-md">
      <div className="max-w-[1080px] flex flex-col h-auto items-center gap-4 px-10 pt-10 pb-8">
        <Formik
          initialValues={{
            title: "",
          }}
          validationSchema={CreateReportSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <>
              <form className="flex flex-col w-[450px]" onSubmit={formik.handleSubmit} noValidate>
                <Typography variant="displayxs" weight="bold" className="text-shade-black text-left !font-bold">
                  Create New Report
                </Typography>

                <div className="flex flex-col gap-2 mt-8">
                  <Typography variant="body2" weight="semibold" className="text-shade-black text-left !font-semibold">
                    Title
                  </Typography>
                  <input
                    className="h-12 mt-[2px] px-4 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-primary-200 focus:ring-primary-200 block w-full rounded-md sm:text-sm focus:ring-1"
                    placeholder="Title"
                    {...formik.getFieldProps("title")}
                    {...formikHelper(formik, "title")}
                    onChange={(event) => {
                      formik.setFieldValue("title", event.target.value);
                    }}
                  />
                </div>

                <div className="flex flex-col gap-2 mt-6">
                  <Typography variant="body2" weight="semibold" className="text-shade-black text-left !font-semibold">
                    Included Data Sources
                  </Typography>
                  <div className="h-36 px-2 py-1 bg-grey-50 shadow-sm  placeholder-grey-400 focus:outline-none focus:border-primary-200 focus:ring-primary-200 w-full rounded-md sm:text-sm focus:ring-1 flex gap-1 flex-col overflow-auto">
                    {dataSources &&
                      dataSources.map((data_source: Partial<IDataSource>) => (
                        <div key={data_source.id} className="">
                          <div className="flex justify-between items-center hover:bg-grey-100 px-[2px] py-1">
                            <div className="flex gap-3 items-center">
                              <img src={getIcon(data_source.type as string)} alt="pdf" className="h-10 w-10" />
                              <Typography variant="textsm" weight="medium">
                                {data_source.name}
                              </Typography>
                            </div>

                            <img
                              src={delete_icon}
                              alt="delete"
                              onClick={() => removeDataSource(data_source.id as string)}
                              className="cursor-pointer"
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="flex justify-between mt-12">
                  <Button type="button" title="Cancel" color="info" onClick={() => onClose()} />
                  <Button
                    onClick={async () => {
                      const validation = await formik.validateForm();
                      if (Object.keys(validation).length) return;
                    }}
                    isSubmitting={isSubmitting || formik.isSubmitting}
                    type="submit"
                    title="Create"
                    disabled={isSubmitting || formik.isSubmitting || !formik.isValid || dataSources.length === 0}
                  />
                </div>
              </form>
            </>
          )}
        </Formik>
      </div>
      <div className="bg-grey-50 w-[406px]  py-10 rounded-r-md">
        <header className="flex flex-col gap-3 px-5 border-b-[1px] border-grey-300 pb-4">
          <Typography variant="displayxs" weight="bold" className="text-shade-black text-left !font-bold">
            Included Data Sources
          </Typography>
          <Typography variant="textsm" weight="regular" className="text-left">
            Select a Data Sources you will like to include.
          </Typography>
        </header>
        {/* <div className="mt-8">
          <div className="relative mt-2 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <img className="text-gray-500 sm:text-sm" alt="search" src={search_icon} />
            </div>

            <input
              className="h-12 px-3 pl-10 py-2 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-primary-200 focus:ring-primary-200 block w-full rounded-md sm:text-sm focus:ring-1"
              placeholder="Search data source"
              onChange={(event) => {
                setSearchTerm(event.target.value);
              }}
            />
          </div>
        </div> */}
        {error && !isFetching && (
          <div className="mt-20 flex justify-center items-center px-5">
            <Typography variant="displayxs" weight="semibold">
              Error fetching data sources
            </Typography>{" "}
          </div>
        )}
        {isFetching && (
          <div className="mt-8 px-5">
            {" "}
            <Loading numberOfLoaders={5} height={10} className="bg-grey-300" />
          </div>
        )}
        {displayNoDataSourcesAvailable && !isFetching && (
          <div className="flex justify-center items-center mt-8 px-5">
            <Typography variant="displayxs" weight="semibold">
              No data source available
            </Typography>{" "}
          </div>
        )}
        <div className="mt-4 flex gap-2 flex-col overflow-auto max-h-[350px] px-5">
          {displayDataSources &&
            !error &&
            !isFetching &&
            sources.map((dataSource: Partial<IDataSource>) => (
              <div key={dataSource.id} className="">
                <div
                  className="flex gap-4 items-center  hover:cursor-pointer hover:bg-grey-100 px-[2px] py-1"
                  onClick={() => addDataSource(dataSource?.id as string, dataSource.name as string, dataSource?.type as string)}
                >
                  <img src={getIcon(dataSource.type as string)} alt="pdf" className="h-10 w-10" />
                  <Typography variant="body2" className="!font-medium">
                    {dataSource.name}
                  </Typography>
                </div>
              </div>
            ))}
        </div>
      </div>{" "}
    </div>
  );
}
