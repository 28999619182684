import { useState } from "react";
import Typography from "../../shared/Typography";
import { IoMdClose } from "react-icons/io";
import { Button } from "../../shared/Button";
import classNames from "classnames";
import { IDataSource } from "types";
import { useAddFilesToFolder } from "services";
import { toast } from "react-toastify";
import { queryClient } from "index";
import { datasources_folder_icon } from "assets/svg";
import { getIcon } from "utils";

interface IAddFilesToFolder {
  onClose: () => void;
  report_id?: string;
  folder_id?: string;
  dataType?: string;
  files: Partial<IDataSource>[];
}

export function AddFilesToFolderModal(props: IAddFilesToFolder) {
  const { onClose, report_id, dataType, folder_id, files } = props;

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [selected, setSelected] = useState<string[]>([]);

  const { addFilesToFolder } = useAddFilesToFolder();

  const handleSelectBenefit = async (item: string) => {
    try {
      let checkedReportState = [...selected];
      if (checkedReportState.includes(item)) checkedReportState = checkedReportState.filter((id) => id !== item);
      else checkedReportState = checkedReportState.concat(item);
      setSelected(checkedReportState);
    } catch (e) {
      console.error(e);
    }
  };

  const addFiles = async () => {
    setIsSubmitting(true);

    const customId = "custom-toast-id";

    addFilesToFolder(
      { id: folder_id as string, data_sources: selected },
      {
        onError: (error, variables, context) => {
          toast.error(error?.message || "Error adding files", {
            position: "top-center",
            toastId: customId,
            autoClose: 2000,
            theme: "colored",
          });
          setIsSubmitting(false);
        },
        onSuccess: (data, variables, context) => {
          queryClient.invalidateQueries({ queryKey: ["datasources"] });
          queryClient.invalidateQueries({ queryKey: ["datasource-folder", folder_id] });

          //   if (report_id) {
          //     queryClient.invalidateQueries({ queryKey: ["report-datasources", report_id] });
          //   }
          onClose();

          toast.success("Files added successfully", {
            position: "top-center",
            toastId: customId,
            autoClose: 1000,
            theme: "colored",
          });
        },
      }
    );
  };

  const disableButton = isSubmitting || selected.length === 0;

  return (
    <div className={classNames("flex bg-[#FFF] flex-col rounded-md w-[470px]")}>
      <div className="">
        <div className="flex justify-between items-center px-6 pb-4 pt-6  border-b-[1px] border-b-grey-100">
          <Typography variant="textxl" className="font-semibold">
            Choose Files
          </Typography>

          <div onClick={() => onClose()} className="hover:cursor-pointer h-6 w-6">
            <IoMdClose className="w-full h-full" />
          </div>
        </div>
        {files?.length === 0 && (
          <div className="flex justify-center items-center h-[150px]">
            <Typography variant="textxl" className="font-semibold">
              No Files available
            </Typography>
          </div>
        )}
        <div className="flex flex-col gap-2 p-6 ">
          {" "}
          {files.map((data, index) => (
            <div className="flex items-center justify-between hover:cursor-pointer hover:bg-grey-75 p-2 rounded-md gap-2" key={data.id}>
              <div className="flex items-center gap-2">
                <img src={getIcon(data.type as string)} alt="folder" className="h-8 w-8" />{" "}
                <Typography variant="textsm" className="text-shade-black">
                  {data.name}
                </Typography>
              </div>
              <div className='w-5'>
                <input
                  type="checkbox"
                  id="radioColor"
                  className="h-5 w-5 hover:cursor-pointer"
                  checked={!!selected?.find((id: any) => id === (data.id as string))}
                  onChange={() => handleSelectBenefit(data.id as string)}
                />
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-between items-center px-6 pb-6 pt-4  border-t-[1px] border-t-grey-100">
          <Button type="button" title="Cancel" color="info" onClick={() => onClose()} />
          <Button title="Add Files" disabled={disableButton} onClick={addFiles} />
        </div>{" "}
      </div>
    </div>
  );
}
