import classNames from "classnames";
import React from "react";
import Typography from "./Typography";

interface IButton {
  title: string;
  icon?: React.ReactNode | string;
  onClick?: (e?: any) => void;
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  color?: "info" | "error" | "primary" | "soft-primary" | "soft-error";
  size?: "small" | "meduim" | "large";
  variant?: "filled" | "outlined" | "text";
  weigth?: "regular" | "medium" | "semibold" | "bold";
  fullwidth?: boolean;
  className?: string;
  background?: string;
  colorText?: string;
  isSubmitting?: boolean;
}

export function Button(props: IButton) {
  const {
    className,
    title,
    icon,
    onClick,
    disabled = false,
    type = "button",
    color = "primary",
    variant,
    weigth = "semibold",
    fullwidth = false,
    background,
    colorText,
    size,
    isSubmitting,
  } = props;

  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={classNames(`${className} rounded-[4px] py-2 px-3 flex items-center gap-2`, {
        "bg-primary-700 hover:bg-primary-800": color === "primary",
        "bg-error-500 hover:bg-error-600": color === "error",
        "bg-primary-50 hover:bg-primary-100 border-[1px] border-primary-700 text-primary-700": color === "soft-primary",
        "bg-white border-[2px] border-grey-200 hover:bg-grey-200": color === "info",
        "bg-white hover:bg-error-50 border-[1px] border-error-400 text-error-400": color === "soft-error",
        "opacity-50": disabled,
        "w-full justify-center": fullwidth,
        "max-w-[500px] w-auto": !fullwidth,
      })}
    >
      {isSubmitting ? (
        <div className="px-2">
          <div className={classNames("buttonSpinner")} />
        </div>
      ) : (
        <>
          {icon && (
            <span
              className={classNames({
                "text-grey-500 hover:text-shade-white": color === "info",
                "text-shade-white": color !== "info",
              })}
            >
              {icon}
            </span>
          )}

          <Typography
            variant="textmd"
            weight={weigth}
            className={classNames({
              "text-grey-500": color === "info",
              "text-shade-white": color !== "info" && color !== "soft-primary" && color !== "soft-error",
              "text-primary-700": color === "soft-primary",
              "text-error-400": color === "soft-error",
            })}
          >
            {title}
          </Typography>
        </>
      )}
    </button>
  );
}
