import { Button } from "components/shared";
import Typography from "components/shared/Typography";
import { MdAdd } from "react-icons/md";
import { useNavigate } from "react-router";

interface IEmptyDatasources {
  title: string;
}

export default function EmptyDatasources(props: IEmptyDatasources) {
  const { title } = props;

  const navigate = useNavigate();
  return (
    <div className="flex justify-center items-center h-full w-full">
      <div className="flex flex-col items-center gap-8">
        <Typography variant="displaylg" className="gradient-text text-center">
          No Data Sources Available
        </Typography>
        <div className="flex justify-end items-center mb-8">
          <Button icon={<MdAdd />} title={title} onClick={() => navigate("/data-sources/upload")} />
        </div>
      </div>
    </div>
  );
}
