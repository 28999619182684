import {
  bar_chart_icon,
  brief_icon,
  chart_icon,
  comprehensive_icon,
  delete_icon,
  detailed_icon,
  line_chart_icon,
  list_icon,
  paragraph_icon,
  pie_chart_icon,
  red_trash_icon,
  table_icon,
} from "assets/svg";
import classNames from "classnames";
import DataSourcesModal from "components/Datasources/modals/DataSourceModal";
import { Modal } from "components/shared/Modal";
import Typography from "components/shared/Typography";
import { useEffect, useState } from "react";
import { IDataSource, ISample } from "types";
import { Button } from "components/shared";
import { GoPlus } from "react-icons/go";
import { offset, useClick, useFloating, useInteractions } from "@floating-ui/react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { useResizeDetector } from "react-resize-detector";
import { IoMdClose } from "react-icons/io";

interface IBlockFormProps {
  removeBlockComponent: Function;
  setBlockProperty: Function;
  getBlockProperty: Function;
  allDataSources: Partial<IDataSource>[];
  blockKey: string;
}

export default function BlockComponentCard(props: IBlockFormProps) {
  const { getBlockProperty, setBlockProperty, removeBlockComponent, allDataSources, blockKey } = props;

  const [sources, setSources] = useState<any>([]);

  useEffect(() => {
    setSources(allDataSources);
  }, []);

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [resultFormat, setResultFormat] = useState<string>("");
  const [resultStyle, setResultStyle] = useState<string>("");
  const [chartType, setChartType] = useState<string>("");

  const [selectedDataSources, setSelectedDataSources] = useState<Partial<IDataSource>[]>([]);

  const handleDataSelection = async (item: Partial<IDataSource>) => {
    try {
      let checkedDataSourceState = [...selectedDataSources];
      if (checkedDataSourceState.includes(item)) checkedDataSourceState = checkedDataSourceState.filter((data) => data.id !== item.id);
      else checkedDataSourceState = checkedDataSourceState.concat(item);
      setSelectedDataSources(checkedDataSourceState);
      setBlockProperty(blockKey, "data_sources", [...checkedDataSourceState.map((source) => source.id)]);
    } catch (e) {
      console.error(e);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset({
        mainAxis: 6,
      }),
    ],
  });

  const click = useClick(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click]);

  const { width, ref } = useResizeDetector();

  const dropdownWidth = classNames(`w-[${width}px]`);

  return (
    <div className="flex flex-col w-full gap-8 py-10 px-5 border-[1.2px] border-grey-200 rounded-md">
      {modalOpen && (
        <Modal
          onClose={() => setModalOpen(false)}
          open={modalOpen}
          variant="full"
          children={
            <DataSourcesModal
              blockKey={blockKey}
              data={allDataSources}
              setBlockProperty={setBlockProperty}
              onClose={setModalOpen}
              handleDataSelection={handleDataSelection}
              selectedDataSources={selectedDataSources}
            />
          }
        />
      )}
      <div className="flex flex-col gap-1">
        <Typography variant="body2" className="text-shade-black text-left !font-semibold">
          Block Title
        </Typography>
        <input
          className="py-4 mt-[2px] px-4 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-primary-200 focus:ring-primary-200 block w-full rounded-md sm:text-sm focus:ring-1"
          placeholder="Block Title"
          onChange={(e) => {
            setBlockProperty(blockKey, "title", e.target.value);
          }}
        />
        {getBlockProperty(blockKey, "title") === "" && (
          <Typography variant="textxs" className="text-error-500 text-left !font-regular">
            Title is required
          </Typography>
        )}
      </div>
      <div className="flex flex-col gap-1">
        <Typography variant="body2" className="!font-semibold text-shade-black text-left">
          Query
        </Typography>
        <textarea
          className="h-28 mt-[2px] py-3 px-4 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-primary-200 focus:ring-primary-200 block w-full rounded-md sm:text-sm focus:ring-1"
          placeholder="Condense this report"
          onChange={(e) => {
            setBlockProperty(blockKey, "query", e.target.value);
          }}
        />
        {getBlockProperty(blockKey, "query") === "" && (
          <Typography variant="textxs" className="text-error-500 text-left !font-regular">
            Query is required
          </Typography>
        )}
      </div>
      <div className="flex justify-start flex-col gap-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <div className={classNames("text-primary-700 flex gap-1 items-center hover:cursor-pointer")} onClick={() => setModalOpen(true)}>
              <GoPlus className="!font-bold h-6 w-6 mb-1" />
              <div className="flex items-center gap-2 mb-1">
                <Typography variant="body2" className="!font-semibold text-primary-700">
                  Add Sources
                </Typography>
              </div>
            </div>
          </div>

          <Button
            color="soft-error"
            icon={<img src={red_trash_icon} alt="Delete" />}
            title="Delete Block"
            className="cursor-pointer"
            onClick={() => removeBlockComponent(blockKey)}
          />
        </div>
      </div>

      {selectedDataSources && selectedDataSources.length > 0 && (
        <div className="flex flex-col gap-1">
          <div className="" ref={ref}>
            <div
              ref={refs.setReference}
              {...getReferenceProps()}
              className="flex items-center justify-between border-[1px] border-grey-200 p-3 rounded-md w-full"
            >
              <Typography variant="textmd" className="text-shade-black" weight="bold">
                Selected Data Sources
              </Typography>
              {isOpen ? <FaAngleUp /> : <FaAngleDown />}
            </div>
            {isOpen && (
              <div
                ref={refs.setFloating}
                style={floatingStyles}
                {...getFloatingProps()}
                className={classNames(
                  `${dropdownWidth} bg-shade-white p-2 border-[1px] border-grey-200 rounded-md shadow-md  flex flex-col gap-3`
                )}
              >
                {selectedDataSources.map((dataSource, index) => (
                  <div key={index} className="w-full flex items-center justify-between gap-2">
                    <div className="truncate">{dataSource.name}</div>
                    <div
                      className="h-8 w-8 rounded-full hover:bg-grey-100 hover:cursor-pointer flex items-center justify-center"
                      onClick={() => handleDataSelection(dataSource as Partial<IDataSource>)}
                    >
                      <IoMdClose className="text-error-500" />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}

      <div className="flex flex-col gap-1">
        <Typography variant="body2" className="!font-semibold text-shade-black text-left">
          Result Format
        </Typography>

        <div className="flex gap-4">
          <div
            className={classNames(
              "w-[88px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 p-2 flex flex-col gap-1 items-center justify-center hover:cursor-pointer",
              {
                "bg-primary-50": resultFormat === "paragraph",
              }
            )}
            onClick={() => {
              setResultFormat("paragraph");
              setBlockProperty(blockKey, "result_format", "paragraph");
            }}
          >
            <img src={paragraph_icon} alt="paragraph" className="w-[20px] h-[20px]" />
            <Typography variant="textsm">Paragraph</Typography>
          </div>
          <div
            className={classNames(
              "w-[88px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 p-2 flex flex-col gap-1 items-center justify-center hover:cursor-pointer",
              {
                "bg-primary-50": resultFormat === "list",
              }
            )}
            onClick={() => {
              setResultFormat("list");
              setBlockProperty(blockKey, "result_format", "list");
            }}
          >
            <img src={list_icon} alt="list" className="w-[20px] h-[20px]" />
            <Typography variant="textsm">List</Typography>
          </div>
          <div
            className={classNames(
              "w-[88px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 p-2 flex flex-col gap-1 items-center justify-center hover:cursor-pointer",
              {
                "bg-primary-50": resultFormat === "table",
              }
            )}
            onClick={() => {
              setResultFormat("table");
              setBlockProperty(blockKey, "result_format", "table");
            }}
          >
            <img src={table_icon} alt="table" className="w-[20px] h-[20px]" />
            <Typography variant="textsm">Table</Typography>
          </div>

          <div
            className={classNames(
              "w-[88px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 p-2 flex flex-col gap-1 items-center justify-center hover:cursor-pointer",
              {
                "bg-primary-50": resultFormat === "chart",
              }
            )}
            onClick={() => {
              setResultFormat("chart");
              setBlockProperty(blockKey, "result_format", "chart");
            }}
          >
            <img src={chart_icon} alt="chart" className="w-[20px] h-[20px]" />
            <Typography variant="textsm">Chart</Typography>
          </div>
        </div>
        {getBlockProperty(blockKey, "result_format") === "" && (
          <Typography variant="textxs" className="text-error-500 text-left !font-regular">
            Result format is required
          </Typography>
        )}
      </div>
      {resultFormat === "table" && (
        <>
          <div className="flex flex-col gap-1">
            <Typography variant="body2" className="text-shade-black text-left !font-semibold">
              Table Prompt
            </Typography>
            <Typography variant="textsm" className="text-shade-black text-left !font-meduim">
              Row description
            </Typography>
            <input
              className="py-4 mt-[2px] px-4 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-primary-200 focus:ring-primary-200 block w-full rounded-md sm:text-sm focus:ring-1"
              placeholder="Row Description"
              onChange={(e) => {
                setBlockProperty(blockKey, "table_row_description", e.target.value);
              }}
            />
            {getBlockProperty(blockKey, "table_row_description") === "" && (
              <Typography variant="textxs" className="text-error-500 text-left !font-regular">
                Table row description is required
              </Typography>
            )}
          </div>

          <div className="flex flex-col gap-1">
            <Typography variant="textsm" className="text-shade-black text-left !font-meduim">
              Columns
            </Typography>
            <input
              className="py-4 mt-[2px] px-4 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-primary-200 focus:ring-primary-200 block w-full rounded-md sm:text-sm focus:ring-1"
              placeholder="Columns"
              onChange={(e) => {
                setBlockProperty(blockKey, "table_columns", e.target.value);
              }}
            />
            {getBlockProperty(blockKey, "table_columns") === "" && (
              <Typography variant="textxs" className="text-error-500 text-left !font-regular">
                Table column details is required
              </Typography>
            )}
          </div>
        </>
      )}
      {resultFormat === "chart" && (
        <>
          <div className="flex flex-col gap-1">
            <Typography variant="body2" className="!font-semibold text-shade-black text-left">
              Chart Type
            </Typography>

            <div className="flex gap-4">
              <div
                className={classNames(
                  "w-[88px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 p-2 flex flex-col gap-1 items-center justify-center hover:cursor-pointer",
                  {
                    "bg-primary-50": chartType === "pie_chart",
                  }
                )}
                onClick={() => {
                  setChartType("pie_chart");
                  setBlockProperty(blockKey, "chart_type", "pie_chart");
                }}
              >
                <img src={pie_chart_icon} alt="brief" className="w-[20px] h-[20px]" />
                <Typography variant="textsm">Pie Chart</Typography>
              </div>
              <div
                className={classNames(
                  "w-[88px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 p-2 flex flex-col gap-1 items-center justify-center hover:cursor-pointer",
                  {
                    "bg-primary-50": chartType === "bar_chart",
                  }
                )}
                onClick={() => {
                  setChartType("bar_chart");
                  setBlockProperty(blockKey, "chart_type", "bar_chart");
                }}
              >
                <img src={bar_chart_icon} alt="comprehensive" className="w-[20px] h-[20px]" />
                <Typography variant="textsm">Bar Chart</Typography>
              </div>
              <div
                className={classNames(
                  "w-[88px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 p-2 flex flex-col gap-1 items-center justify-center hover:cursor-pointer",
                  {
                    "bg-primary-50": chartType === "line_chart",
                  }
                )}
                onClick={() => {
                  setChartType("line_chart");
                  setBlockProperty(blockKey, "chart_type", "line_chart");
                }}
              >
                <img src={line_chart_icon} alt="detailed" className="w-[20px] h-[20px]" />
                <Typography variant="textsm">Line Chart</Typography>
              </div>
            </div>
            {getBlockProperty(blockKey, "chart_type") === "" && (
              <Typography variant="textxs" className="text-error-500 text-left !font-regular">
                Chart type is required
              </Typography>
            )}
          </div>

          <div className="flex flex-col gap-1">
            <Typography variant="body2" className="text-shade-black text-left !font-semibold">
              Chart Instruction
            </Typography>
            <input
              className="py-4 mt-[2px] px-4 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-primary-200 focus:ring-primary-200 block w-full rounded-md sm:text-sm focus:ring-1"
              placeholder="Chart instruction"
              onChange={(e) => {
                setBlockProperty(blockKey, "chart_instruction", e.target.value);
              }}
            />
            {getBlockProperty(blockKey, "chart_instruction") === "" && (
              <Typography variant="textxs" className="text-error-500 text-left !font-regular">
                Chart instruction is required
              </Typography>
            )}
          </div>
        </>
      )}
      <div className="flex flex-col gap-1">
        <Typography variant="body2" className="!font-semibold text-shade-black text-left">
          Result Style
        </Typography>

        <div className="flex gap-4">
          <div
            className={classNames(
              "w-[88px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 p-2 flex flex-col gap-1 items-center justify-center hover:cursor-pointer",
              {
                "bg-primary-50": resultStyle === "brief",
              }
            )}
            onClick={() => {
              setResultStyle("brief");
              setBlockProperty(blockKey, "result_style", "brief");
            }}
          >
            <img src={brief_icon} alt="brief" className="w-[20px] h-[20px]" />
            <Typography variant="textsm">Brief</Typography>
          </div>
          <div
            className={classNames(
              "w-[120px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 p-2 flex flex-col gap-1 items-center justify-center hover:cursor-pointer",
              {
                "bg-primary-50": resultStyle === "comprehensive",
              }
            )}
            onClick={() => {
              setResultStyle("comprehensive");
              setBlockProperty(blockKey, "result_style", "comprehensive");
            }}
          >
            <img src={comprehensive_icon} alt="comprehensive" className="w-[20px] h-[20px]" />
            <Typography variant="textsm">Comprehensive</Typography>
          </div>
          <div
            className={classNames(
              "w-[88px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 p-2 flex flex-col gap-1 items-center justify-center hover:cursor-pointer",
              {
                "bg-primary-50": resultStyle === "detailed",
              }
            )}
            onClick={() => {
              setResultStyle("detailed");
              setBlockProperty(blockKey, "result_style", "detailed");
            }}
          >
            <img src={detailed_icon} alt="detailed" className="w-[20px] h-[20px]" />
            <Typography variant="textsm">Detailed</Typography>
          </div>
        </div>

        {getBlockProperty(blockKey, "result_style") === "" && (
          <Typography variant="textxs" className="text-error-500 text-left !font-regular">
            Result style is required
          </Typography>
        )}
      </div>
      <div className="flex flex-col gap-1 mt-6">
        <Typography variant="body2" className="!font-semibold text-shade-black text-left">
          Extra Information (Optional)
        </Typography>
        <textarea
          placeholder="Extra Information"
          className="h-28 mt-[2px] px-3 py-2 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-primary-200 focus:ring-primary-200 block w-full rounded-md sm:text-sm focus:ring-1"
          onClick={(e: any) => {
            setBlockProperty(blockKey, "extra_information", e.target.value);
          }}
        />
      </div>
    </div>
  );
}
