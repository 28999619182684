import Typography from "components/shared/Typography";
import { AppContext } from "providers/AppContext";
import React, { useContext, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { cleanReferenceString, getIcon } from "utils";
import classNames from "classnames";

export interface Metadata {
  filename: string;
  filetype: string;
  languages: string[];
  coordinates: Coordinates;
  page_number: number;
  explanation?: string;
}

export interface Coordinates {
  points: number[][];
  system: string;
  layout_width: number;
  layout_height: number;
}

export interface ReferenceCardProps {
  reference: any;
}

export interface DataSource {
  data_source_id: string;
  data_source_name: string;
  data_source_type: string;
  contents: IReference[];
}

export interface IReference {
  id: number;
  data_source_id: string;
  report_block_id: number;
  type: string;
  format: string;
  content: any[];
  metadata: Metadata;
  account_id: string | null;
  created: string;
  updated: string;
}

enum ActiveReference {
  EXPLANATION = "explanation",
  SQL = "sql",
}

export function ReferenceCard({ reference }: ReferenceCardProps) {
  const [openIndex, setOpenIndex] = useState(null);
  const [content, setContent] = useState<Partial<DataSource>>();

  const { togglePdfModal, setDataSource, startDataSourceDownload, setPage, setDataSourceType, isPdfOpen, setDataSourceName } = useContext(AppContext);

  const dataSourceId = reference?.references[0]?.contents[0]?.data_source_id as string;

  const dataSourceType = reference?.references[0]?.data_source_type as string;

  const dataSourceName = reference?.references[0]?.data_source_name as string;

  const dataSourceTypeIsPdfOrDocx = dataSourceType === "pdf" || dataSourceType === "docx";

  const handleReferenceNavigation = (id: number) => {
    setPage(id as number);
    if (dataSourceTypeIsPdfOrDocx) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const toggleDropdown = (index: any, selectedReference: DataSource) => {
    if (dataSourceTypeIsPdfOrDocx && isPdfOpen) {
      setDataSource(selectedReference?.data_source_id as string);
      startDataSourceDownload();
      setDataSourceType(selectedReference.data_source_type as string);
      setDataSourceName(selectedReference.data_source_name);
    }
    if (dataSourceTypeIsPdfOrDocx && !isPdfOpen) {
      setDataSource(selectedReference?.data_source_id as string);
      togglePdfModal();
      startDataSourceDownload();
      setDataSourceType(selectedReference.data_source_type as string);
      setDataSourceName(selectedReference.data_source_name);
    }
    const referenceToggleOpenVariable = dataSourceTypeIsPdfOrDocx ? isPdfOpen && openIndex === index : openIndex === index;
    setOpenIndex(referenceToggleOpenVariable ? null : index);
    setContent(
      referenceToggleOpenVariable ? null : reference?.references.find((ref: any) => ref.data_source_id === selectedReference.data_source_id)
    );
  };


  const referenceOpen = dataSourceTypeIsPdfOrDocx ? content?.contents && isPdfOpen : content?.contents;

  const [activeReference, setActiveReference] = useState<ActiveReference>(ActiveReference.SQL);

  return (
    <div className="w-full flex flex-col">
      <div className="flex items-center w-full gap-2">
        <div className="flex items-start h-full">
          <Typography variant="textsm" className="weigth-regular">
            Reference:
          </Typography>
        </div>
        <div className="flex items-end gap-4 flex-wrap">
          {reference?.references.map((ref: any, index: any) => (
            <div key={index} className="flex">
              <button
                onClick={() => toggleDropdown(index, ref)}
                className="flex items-center  justify-between w-full ml-1 bg-gray-100 hover:bg-gray-200 rounded-md"
              >
                <div className="flex items-center flex-grow gap-2">
                  <img src={getIcon(ref.data_source_type) as string} alt="data source ison" className="h-6 w-6" />
                  <Typography variant="textsm" className="weigth-regular">
                    {ref.data_source_name}
                  </Typography>
                </div>
                <span className="ml-1">{openIndex === index ? <FaChevronUp size={15} /> : <FaChevronDown size={15} />}</span>
              </button>
            </div>
          ))}
        </div>
      </div>

      {referenceOpen && (
        <div className={`flex flex-col gap-4 w-full max-w-[900px] mt-4 bg-grey-200 p-4 rounded-md`}>
          {content?.contents &&
            content?.contents.map((item, index) => (
              <div key={index} className="flex flex-col gap-2">
                {item.type === "pdf" && (
                  <div
                    onClick={() => handleReferenceNavigation(item?.metadata?.page_number)}
                    className="hover:cursor-pointer hover:bg-shade-white p-2 rounded-md"
                  >
                    <Typography variant="textxs" className="text-sm" weight="regular">
                      {`

                  ${cleanReferenceString(item?.content as unknown as string)}
                     

                    
                      
                        ${String(item?.metadata?.page_number).length > 0 && ` - [Page ${item.metadata.page_number}]`}`}
                    </Typography>
                  </div>
                )}

                {item.type === "docx" && (
                  <div className="hover:cursor-pointer hover:bg-shade-white p-2 rounded-md ">
                    <Typography variant="textxs" className="text-sm" weight="regular">
                      {cleanReferenceString(item?.content as unknown as string)}
                    </Typography>
                  </div>
                )}

                {/* {(item.type === "sql" || item.type === "csv" || item.type === "xlsx") && (
                  <div>
                    <div className="flex items-center">
                      <div
                        className={classNames(
                          "flex justify-between items-center border-[1px] border-grey-300  p-2 hover:cursor-pointer hover:bg-shade-white rounded-l-md",
                          {
                            "bg-shade-white": activeReference === ActiveReference.SQL,
                          }
                        )}
                        onClick={() => setActiveReference(ActiveReference.SQL)}
                      >
                        <Typography
                          variant="textsm"
                          weight={activeReference === ActiveReference.SQL ? "semibold" : "regular"}
                          className="text-shade-black"
                        >
                          SQL
                        </Typography>
                      </div>
                      <div
                        className={classNames(
                          "flex justify-between items-center border-r-[1px] border-b-[1px]  border-t-[1px]  border-grey-300  p-2 hover:cursor-pointer hover:bg-shade-white rounded-r-md",
                          {
                            "bg-shade-white": activeReference === ActiveReference.EXPLANATION,
                          }
                        )}
                        onClick={() => setActiveReference(ActiveReference.EXPLANATION)}
                      >
                        {" "}
                        <Typography
                          variant="textsm"
                          weight={activeReference === ActiveReference.EXPLANATION ? "semibold" : "regular"}
                          className="text-shade-black"
                        >
                          Explanation
                        </Typography>
                      </div>
                    </div>
                    <div className="hover:cursor-pointer hover:bg-shade-white p-2 rounded-md">
                      {activeReference === ActiveReference.SQL && (
                        <MarkdownPreview
                          source={`
\`\`\`sql
${item.content}
\`\`\`
`}
                          style={{ padding: 16 }}
                        />
                      )}
                      {activeReference === ActiveReference.EXPLANATION && (
                        <Typography variant="textsm" className="regular mt-2">
                          {item.metadata?.explanation}
                        </Typography>
                      )}
                    </div>
                  </div>
                )} */}
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
