import { Text } from "@chakra-ui/react";
import { edit_note_icon } from "assets/svg";
import { ChartData } from "chart.js";
import classNames from "classnames";
import Loading from "components/shared/Loading";
import { Modal } from "components/shared/Modal";
import { RegenerateBlockModal } from "components/blocks/RegenerateBlockModal";
import { Tooltip } from "components/shared/Tooltip";
import Typography from "components/shared/Typography";
import CustomChart from "components/chart/CustomChart";
import { useEffect, useState } from "react";
import { BsChatDots } from "react-icons/bs";
import { IoCheckmarkSharp } from "react-icons/io5";
import { LuPencilLine, LuRefreshCcw } from "react-icons/lu";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useEditBlockResult, useFetchBlockReferences, useFetchBlockResult } from "services";
import { BlockResult, IBlockCardProps, IResultFormat, IResultTypes, ResultStatus } from "types";
import { cleanString } from "utils";
import Table from "./Table";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { ReferenceCard } from "./Reference";
import { Chat, IChatMessages } from "./Chat";
import BlockQueryError from "./BlockQueryError";
import BlockItem from "./BlockItem";

export function BlockCard(props: IBlockCardProps) {
  const navigate = useNavigate();
  const { block, reportId } = props;

  const { title, last_result, id, result_format, result_status, results } = block;

  console.log(results, "results");
  
  
  // const resultAvailable = results && results?.filter((r) => r.content !== null && r.content !== undefined).length > 0;

  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isRedoing, setIsRedoing] = useState<boolean>(false);
  const [resultType, setResultType] = useState<IResultTypes>();
  const [chartData, setChartData] = useState<ChartData<"pie">>();



  const customId = "custom-toast-id";

  const blockId = String(block.id);
  const [comment, setComment] = useState("");

  // const {
  //   data: blockResult,
  //   isFetching: isFetchingBlock,
  //   error: isBlockError,
  //   isSuccess: isBlockSuccess,
  //   refetch,
  // } = useFetchBlockResult(reportId, blockId, !resultAvailable, isRedoing, comment);


  // const handleReferesh = () => {
  //   setIsRedoing(true);
  //   setTimeout(() => {
  //     refetch();
  //   }, 200);
  //   setIsUpdateBlockModalOpen(false);
  // };
  // const desctucturedLastResultFromBlock = blockResult?.data?.result?.results;

  // const desctucturedResultStatusFromBlock = blockResult?.data?.result?.block?.result_status;


  const computed_result_status = result_status;

  const {
    data: blockReferences,
    isFetching: isFetchingBlockReferences,
    error: isBlockReferencesError,
  } = useFetchBlockReferences(reportId, blockId, Boolean(results) && computed_result_status !== "error");

  // const chartFormat =
  //   desctucturedLastResultFromBlock && blockResult?.data?.result?.block?.result_type === "json_string"
  //     ? blockResult?.data?.result?.block?.result_type
  //     : result_type;

  // useEffect(() => {
  //   if (result_type || blockResult?.data?.result?.block?.result_type) {
  //     setResultType((result_type as IResultTypes) || blockResult?.data?.result?.block?.result_type);
  //   }
  // }, [result_type]);

  // useEffect(() => {
  //   if (chartFormat === "json_string" && result && result.length === 0 && computed_result_status !== ResultStatus.ERROR) {
  //     setChartData(JSON.parse(result as string));
  //   }
  // }, [result]);

  // const responseResultType = last_result && result_type ? result_type : blockResult?.data?.result?.block?.result_type;

  const { editBlock } = useEditBlockResult();
  const handleEditBlock = async (id: number) => {
    setIsEditable(true);
    setIsUpdating(true);
  };

  // const updateBlock = async (id: number) => {
  //   const updatedContent = document.getElementById(String(id))?.innerText;

  //   const data = {
  //     content: String(updatedContent),
  //     reportId,
  //     blockId: String(id),
  //   };

  //   setIsEditable(false);
  //   editBlock(data, {
  //     onError: (error, variables, context) => {
  //       setIsUpdating(false);
  //       toast.error("Error updating report block", {
  //         position: "top-center",
  //         toastId: customId,
  //         autoClose: 2000,
  //         theme: "colored",
  //       });
  //     },
  //     onSuccess: (data, variables, context) => {
  //       setIsUpdating(false);
  //       toast.success("Report block successfully updated", {
  //         position: "top-center",
  //         toastId: customId,
  //         autoClose: 1500,
  //         theme: "colored",
  //       });
  //     },
  //   });
  // };

  const [isOpen, setIsChatOpen] = useState(false);
  const [isUpdateBlockModalOpen, setIsUpdateBlockModalOpen] = useState(false);

  const toggleSidebar = () => {
    setIsChatOpen(!isOpen);
  };

  let clean: any = null;
  let tableToRender: string | null = null;

  // switch (true) {
  //   case result_format === IResultFormat.TABLE &&
  //     result &&
  //     responseResultType === "json_string" &&
  //     desctucturedResultStatusFromBlock !== ResultStatus.ERROR:
  //     tableToRender = "html";
  //     clean = cleanString(result as string);
  //     break;
  //   default:
  //     break;
  // }

  // const displayBlock = (content === null ? isBlockSuccess : content) && !isBlockError;

  // const [chatMessages, setChatMessages] = useState<IChatMessages[]>([]);


  return (
    <div className="flex w-full">
      <div className="w-full">
        {/* <div
          className={`fixed top-0 right-0 h-full  bg-shade-white shadow-lg z-40 transform transition-transform duration-300 ease-in-out ${
            isOpen ? "translate-x-0 w-[40%]" : "translate-x-full "
          }`}
        >
          <Chat
            report_id={reportId}
            block_id={blockId}
            previous_response={result as string}
            onClose={toggleSidebar}
            setChatMessages={setChatMessages}
            chatMessages={chatMessages}
            open={isOpen}
          />
        </div> */}

        {isOpen && <div className="fixed inset-0 bg-shade-black bg-opacity-50 z-30" onClick={toggleSidebar}></div>}
        {/* {isUpdateBlockModalOpen && (
          <Modal
            onClose={() => setIsUpdateBlockModalOpen(false)}
            open={isUpdateBlockModalOpen}
            variant="full"
            children={
              <RegenerateBlockModal
                handleRefresh={handleReferesh}
                onClose={() => setIsUpdateBlockModalOpen(false)}
                setComment={setComment}
                isFetchingBlock={isFetchingBlock}
              />
            }
          />
        )} */}

        {/* <div className="">
          <div className="text-left mb-2">
            <Typography variant="displaysm" weight="semibold" className="text-shade-black">
              {title}{" "}
            </Typography>
          </div>
          <div className="flex justify-between items-center w-full">
            <Typography variant="textsm">
              Last updated {time} - {date}
            </Typography>

            <div className="flex gap-3 items-center">
              {isEditable ? (
                <Tooltip text="Complete">
                  <div
                    className={classNames("p-[18px] flex gap-2 bg-grey-75 rounded-md items-center justify-center hover:cursor-pointer", {
                      "pointer-events-none bg-grey-200 opacity-30": result_type !== IResultTypes.TEXT,
                      //|| isFetchingBlock || isBlockError,
                      // })},
                    })}
                    onClick={() => updateBlock(id as number)}
                  >
                    <div aria-label="edit complete">
                      {" "}
                      <IoCheckmarkSharp aria-disabled={isUpdating} />{" "}
                    </div>
                  </div>
                </Tooltip>
              ) : (
                <Tooltip text="Update">
                  <div
                    className={classNames("p-[14px] flex gap-2 bg-grey-75 rounded-md items-center justify-center hover:cursor-pointer", {
                      "pointer-events-none bg-grey-200 opacity-30": result_type !== IResultTypes.TEXT || result_format === IResultFormat.TABLE,
                      //|| isFetchingBlock || isBlockError,
                    })}
                    onClick={() => handleEditBlock(id as number)}
                  >
                    <div aria-label="edit">
                      <img src={edit_note_icon} alt="edit" />
                    </div>
                  </div>
                </Tooltip>
              )}

              <Tooltip text="Regenerate">
                <div
                  className={classNames(
                    "p-[18px] flex gap-2 bg-grey-75 hover:bg-grey-100 rounded-md items-center justify-center hover:cursor-pointer"
                    // {
                    //   "pointer-events-none opacity-30": isFetchingBlock,
                    // }
                  )}
                  onClick={() => setIsUpdateBlockModalOpen(true)}
                >
                  <div aria-label="refresh">
                    <LuRefreshCcw />
                  </div>
                </div>
              </Tooltip>
              <Tooltip text="Edit">
                <div
                  className={classNames(
                    "p-[18px] flex gap-2 bg-grey-75  hover:bg-grey-100 rounded-md items-center justify-center hover:cursor-pointer"
                    // {
                    //   "pointer-events-none bg-grey-200 opacity-30": isFetchingBlock,
                    // }
                  )}
                  onClick={() => navigate(`/blocks/${reportId}/edit/${id}`)}
                >
                  <div aria-label="update">
                    <LuPencilLine />
                  </div>
                </div>
              </Tooltip>
              <Tooltip text="Chat">
                <div
                  className={classNames(
                    "p-[18px] flex gap-2 bg-grey-75 hover:bg-grey-100 rounded-md items-center justify-center hover:cursor-pointer",
                    {
                      // "pointer-events-none bg-grey-200 opacity-30": !result,
                      //|| isFetchingBlock || isBlockError,
                    }
                  )}
                  onClick={toggleSidebar}
                >
                  <div aria-label="chat">
                    <BsChatDots />
                  </div>
                </div>
              </Tooltip>
            </div>
          </div>
        </div> */}

        {/* {isFetchingBlock && (
          <div className="mt-[60px]">
            <Loading numberOfLoaders={1} className="bg-grey-200 !h-80" />
          </div>
        )}

        {isBlockError && (
          <div className="my-[60px] py-[60px] flex justify-center items-center border-[1px] border-grey-100">
            <Typography variant="displaysm" weight="semibold" className="text-shade-black text-center">
              There was an unexpected error while retrieving block
            </Typography>
          </div>
        )} */}

        <>
          <>
            {" "}
            {computed_result_status === ResultStatus.ERROR && (
                <BlockQueryError reportId={reportId} blockId={String(id)} message={"An error occurred while retrieving block"} />
              )}
            {computed_result_status !== ResultStatus.ERROR && (
              <div className="mt-10 w-full text-justify">
                {/* {results?.map((block_result) => (
                  <div key={block_result.id}> */}
                <BlockItem
                  // data={block_result}
                  results={results as BlockResult[]}
                  computed_result_status={computed_result_status}
                  resultType={resultType}
                  result_format={result_format}
                  isEditable={isEditable}
                  reportId={reportId}
                  blockId={String(id)}
                  title={title as string}
                />
                {/* {" "}
                      {resultType === IResultTypes.TEXT && result_format !== IResultFormat.TABLE && (
                        <>
                          <Text whiteSpace={"pre-line"} borderWidth={isEditable ? "2px" : "0px"} contentEditable={isEditable} id={String(id)}>
                            {" "}
                            <MarkdownPreview
                              source={`${result}`}
                              wrapperElement={{
                                "data-color-mode": "light",
                              }}
                            />
                          </Text>
                        </>
                      )}
                      {result_format === IResultFormat.CHART && chartFormat === IResultTypes.JSON_STRING && result && chartData && (
                        <>
                          <div className="border-[1px] border-grey-100 rounded-md p-4 w-full">
                            <CustomChart chartData={result} chartType={chart_type || "pie_chart"} title={title as string} />
                          </div>
                        </>
                      )}
                      {tableToRender === "html" && (
                        <>
                          <Table data={clean as any} />
                        </>
                      )}{" "} */}
                {/* </div>
                ))} */}

                <div className="mt-8">
                  {isBlockReferencesError && (
                    <div className="flex justify-start items-center">
                      <Typography variant="textmd" weight="semibold" className="text-shade-black text-left">
                        There was an unexpected error while retrieving References
                      </Typography>
                    </div>
                  )}

                  {isFetchingBlockReferences && !blockReferences && (
                    <div className="">
                      <Loading numberOfLoaders={1} className="bg-grey-200 !h-4" />
                    </div>
                  )}
                  {blockReferences?.data?.references.length > 0 && (
                    <div className="flex gap-4 mt-8">
                      <ReferenceCard reference={blockReferences?.data} />
                    </div>
                  )}

                 
                </div>
              </div>
            )}
          </>
        </>
      </div>
    </div>
  );
}
