import { blue_folder_icon, datasources_folder_icon } from "assets/svg";
import { Button } from "components/shared";
import Typography from "components/shared/Typography";
import { MdAdd } from "react-icons/md";
import { useNavigate } from "react-router";

interface IEmptyFolder {
  setOpen: (value: boolean) => void;
  empty: boolean;
  folderId?: string;
  folderName?: string;
}

export default function EmptyFolder(props: IEmptyFolder) {
  const { setOpen, empty, folderId, folderName } = props;
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div className="flex flex-col items-center gap-8">
        <img src={empty ? blue_folder_icon : datasources_folder_icon} alt="folder" />
        <div className="flex flex-col items-center gap-3">
          <Typography variant="displaysm" className="text-center text-shade-black font-bold">
            {empty ? "Empty Folder" : "Your Folders"}
          </Typography>
          <Typography variant="textsm" className=" text-center regular">
            {empty ? "Click the upload button to add your first file" : "Organize your files and create a folder"}
          </Typography>
        </div>
        <div className="flex gap-4 items-center mb-8">
          <Button icon={<MdAdd />} title={"Create Folder"} onClick={() => setOpen(true)} />
          <Button
            icon={<MdAdd />}
            title={"Upload Data Sources"}
            onClick={() => navigate(`/data-sources/upload?folder_id=${folderId}&name=${folderName}`)}
          />
        </div>
      </div>
    </div>
  );
}
