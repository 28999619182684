import { useContext } from "react";
import Typography from "./Typography";
import { AppContext } from "providers/AppContext";
import classNames from "classnames";

interface IHeaderProps {
  title: string;
}
export default function Header(props: IHeaderProps) {
  const { title } = props;

  const { isPdfOpen } = useContext(AppContext);

  return (
    <div
      className={classNames("flex justify-center items-center mb-8 ", {
        "w-[700px]": !isPdfOpen,
        "w-full": isPdfOpen,
      })}
    >
      <Typography variant="displaylg" weight="bold" className="text-shade-black font-bold">
        {title}
      </Typography>
    </div>
  );
}
