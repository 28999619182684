import { IDataSource } from "./data-source.type";

export interface ICreateReport {
  title: string;
  data_sources: Partial<IDataSource>[];
}

export interface IReportsAPIResponse {
  data: {
    reports: Partial<IReport>[];
    total: number;
  };
}

export interface IReportsArray {
  reports: Partial<IReport>[];
}

export interface IReport {
  created: string;
  id: string;
  title: string;
  updated: string;
}

export enum IResultFormat {
  PARAGRAPH = "paragraph",
  LIST = "list",
  TABLE = "table",
  CHART = "chart",
}

export enum ResultStyle {
  BRIEF = "brief",
  INCLUSIVE = "inclusive",
  DETAILED = "detailed",
}

export interface AuthResponse {
  data: IUser;
  message: string;
  status: number;
  error: string;
  success: boolean;
}

export interface IUser {
  id: string;
  token: string;
  email: string;
  is_org: boolean;
  organization_id: string;
  name: string;
  created: string;
  updated: string;
}

export interface ICreateUser {
  email: string;
  password: string;
  name: string;
}

export interface ILogin {
  email: string;
  password: string;
}

export interface IRegister {
  name: string;
  email: string;
  password: string;
}


export enum ResultStatus {
  SUCCESS = "success",
  ERROR = "error",
}